import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import {
  ISelectPidFilePayload,
  IUploadPidFileEvent,
} from "../../interfaces/pids";

enum ACTION_TYPES {
  UPLOAD_START = "[PID TARGETING FILE] Upload start",
  UPLOAD_ERROR = "[PID TARGETING FILE] Upload error",
  UPLOAD_SUCCESS = "[PID TARGETING FILE] Upload success",

  ON_SELECT_FILE = "[PID TARGETING] On select file",
  ON_SELECT_FILE_UPLOAD = "[PID TARGETING] On select file upload",

  ON_SELECT_PID_NID_FILE_UPLOAD = "[PID TARGETING] On select custom pid nid file upload",

  LISTEN_UPLOAD_SOCKET = "[PID TARGETING FILE] start listen upload socket",
  FILE_SAVE_PROCESS = "[PID TARGETING FILE] Save file progress",
  FILE_SAVE_PROCESS_SUCCESS = "[PID TARGETING FILE] Save file progress success",

  RESET_ERROR = "[PID TARGETING FILE] reset error",

  RESET = "[PID TARGETING FILE] reset state",
}

export const pidFileUploadStartAction: CreateActionType<
  ACTION_TYPES.UPLOAD_START,
  IUploadPidFileEvent
> = createAction(
  ACTION_TYPES.UPLOAD_START,
  createPayload<IUploadPidFileEvent>(),
);
export const pidFileUploadErrorAction: CreateActionType<
  ACTION_TYPES.UPLOAD_ERROR,
  {
    segmentCriteriaId: number;
    error: IServerError;
  }
> = createAction(
  ACTION_TYPES.UPLOAD_ERROR,
  createPayload<{
    segmentCriteriaId: number;
    error: IServerError;
  }>(),
);
export const pidFileUploadSuccessAction: ActionCreator<
  ACTION_TYPES.UPLOAD_SUCCESS,
  () => Action<ACTION_TYPES.UPLOAD_SUCCESS>
> = createAction(ACTION_TYPES.UPLOAD_SUCCESS);
export const pidFileUploadResetErrorAction: ActionCreator<
  ACTION_TYPES.RESET_ERROR,
  () => Action<ACTION_TYPES.RESET_ERROR>
> = createAction(ACTION_TYPES.RESET_ERROR);

export const onPidFileSelectAction: CreateActionType<
  ACTION_TYPES.ON_SELECT_FILE,
  ISelectPidFilePayload
> = createAction(
  ACTION_TYPES.ON_SELECT_FILE,
  createPayload<ISelectPidFilePayload>(),
);
export const onPidFileSelectUploadAction: CreateActionType<
  ACTION_TYPES.ON_SELECT_FILE_UPLOAD,
  ISelectPidFilePayload
> = createAction(
  ACTION_TYPES.ON_SELECT_FILE_UPLOAD,
  createPayload<ISelectPidFilePayload>(),
);
export const onPidNidFileSelectUploadAction: CreateActionType<
  ACTION_TYPES.ON_SELECT_PID_NID_FILE_UPLOAD,
  ISelectPidFilePayload
> = createAction(
  ACTION_TYPES.ON_SELECT_PID_NID_FILE_UPLOAD,
  createPayload<ISelectPidFilePayload>(),
);

export const listenUploadFileSocketAction: ActionCreator<
  ACTION_TYPES.LISTEN_UPLOAD_SOCKET,
  () => Action<ACTION_TYPES.LISTEN_UPLOAD_SOCKET>
> = createAction(ACTION_TYPES.LISTEN_UPLOAD_SOCKET);

export const pidFileSaveProgressAction: CreateActionType<
  ACTION_TYPES.FILE_SAVE_PROCESS,
  IUploadPidFileEvent
> = createAction(
  ACTION_TYPES.FILE_SAVE_PROCESS,
  createPayload<IUploadPidFileEvent>(),
);
export const pidFileSaveProgressSuccessAction: CreateActionType<
  ACTION_TYPES.FILE_SAVE_PROCESS_SUCCESS,
  IUploadPidFileEvent
> = createAction(
  ACTION_TYPES.FILE_SAVE_PROCESS_SUCCESS,
  createPayload<IUploadPidFileEvent>(),
);

export const pidFileResetStateAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => Action<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
