import { Params } from "@angular/router";
import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import {
  IInstitutionsRecords,
  IProductResultsResponse,
} from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

export enum ACTION_TYPES {
  GET_QUICK_SEARCH_ALL_INSTITUTION = "[QUICK SEARCH] Get All Institution",
  GET_QUICK_SEARCH_ALL_INSTITUTION_ERROR = "[QUICK SEARCH] Get All Institution error",
  GET_QUICK_SEARCH_ALL_INSTITUTION_SUCCESS = "[QUICK SEARCH] Get All Institution success",

  CHANGE_QUICK_SEARCH_ALL_INSTITUTION_QUERY = "[QUICK SEARCH] Change all Institution query",
  CHANGE_QUICK_SEARCH_ALL_INSTITUTION_PAGE = "[QUICK SEARCH] Change all Institution page",

  RESET_QUICK_SEARCH_ALL_INSTITUTION_STATE = "[QUICK SEARCH] Reset All Institution state",
}

export const getQuickSearchAllInstitutionAction: ActionCreator<
  ACTION_TYPES.GET_QUICK_SEARCH_ALL_INSTITUTION,
  () => Action<ACTION_TYPES.GET_QUICK_SEARCH_ALL_INSTITUTION>
> = createAction(ACTION_TYPES.GET_QUICK_SEARCH_ALL_INSTITUTION);
export const getQuickSearchAllInstitutionErrorAction: CreateActionType<
  ACTION_TYPES.GET_QUICK_SEARCH_ALL_INSTITUTION_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_QUICK_SEARCH_ALL_INSTITUTION_ERROR,
  createPayload<IServerError>(),
);
export const getQuickSearchAllInstitutionSuccessAction: CreateActionType<
  ACTION_TYPES.GET_QUICK_SEARCH_ALL_INSTITUTION_SUCCESS,
  IProductResultsResponse<IInstitutionsRecords>
> = createAction(
  ACTION_TYPES.GET_QUICK_SEARCH_ALL_INSTITUTION_SUCCESS,
  createPayload<IProductResultsResponse<IInstitutionsRecords>>(),
);

export const changeQuickSearchAllInstitutionQueryAction: CreateActionType<
  ACTION_TYPES.CHANGE_QUICK_SEARCH_ALL_INSTITUTION_QUERY,
  Params
> = createAction(
  ACTION_TYPES.CHANGE_QUICK_SEARCH_ALL_INSTITUTION_QUERY,
  createPayload<Params>(),
);

export const changeQuickSearchAllInstitutionPageAction: CreateActionType<
  ACTION_TYPES.CHANGE_QUICK_SEARCH_ALL_INSTITUTION_PAGE,
  number
> = createAction(
  ACTION_TYPES.CHANGE_QUICK_SEARCH_ALL_INSTITUTION_PAGE,
  createPayload<number>(),
);

export const resetQuickSearchAllInstitutionStateAction: ActionCreator<
  ACTION_TYPES.RESET_QUICK_SEARCH_ALL_INSTITUTION_STATE,
  () => Action<ACTION_TYPES.RESET_QUICK_SEARCH_ALL_INSTITUTION_STATE>
> = createAction(ACTION_TYPES.RESET_QUICK_SEARCH_ALL_INSTITUTION_STATE);
