import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { IOrderCountsPayload } from "../../interfaces";

export enum ACTION_TYPES {
  GET = "[CDL ORDER SUMMARY] Get order counts",
  GET_ERROR = "[CDL ORDER SUMMARY] Get order counts error",
  GET_SUCCESS = "[CDL ORDER SUMMARY] Get order counts success",

  RESET = "[CDL ORDER SUMMARY] Reset State",
}

export const getCDLOrderCountsAction: ActionCreator<
  ACTION_TYPES.GET,
  () => Action<ACTION_TYPES.GET>
> = createAction(ACTION_TYPES.GET);
export const getCDLOrderCountsErrorAction: CreateActionType<
  ACTION_TYPES.GET_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_ERROR, createPayload<IServerError>());
export const getCDLOrderCountsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_SUCCESS,
  IOrderCountsPayload
> = createAction(
  ACTION_TYPES.GET_SUCCESS,
  createPayload<IOrderCountsPayload>(),
);

export const resetOrderSummaryStateAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => Action<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
