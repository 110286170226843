import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";

import {
  IMultiCodesRequest,
  INameAndPidCodesSuccess,
} from "../../../segment/models/codes";

enum ACTION_TYPES {
  GET = "[NAME AND PID CODES] Get",
  GET_ERROR = "[NAME AND PID CODES] Get Error",
  GET_SUCCESS = "[NAME AND PID CODES] Get Success",

  RESET = "[NAME AND PID CODES] Reset State",
}

export const getNameAndPidCodesAction: CreateActionType<
  ACTION_TYPES.GET,
  Partial<IMultiCodesRequest>
> = createAction(
  ACTION_TYPES.GET,
  createPayload<Partial<IMultiCodesRequest>>(),
);
export const getNameAndPidCodesErrorAction: CreateActionType<
  ACTION_TYPES.GET_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_ERROR, createPayload<IServerError>());
export const getNameAndPidCodesSuccessAction: CreateActionType<
  ACTION_TYPES.GET_SUCCESS,
  INameAndPidCodesSuccess
> = createAction(
  ACTION_TYPES.GET_SUCCESS,
  createPayload<INameAndPidCodesSuccess>(),
);

export const resetNameAndPidCodesStateAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => Action<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
