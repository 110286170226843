// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  padding: 20px 20px 32px;
  max-width: 1080px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  :host {
    padding: 20px 15px 32px;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInByb2ZpbGUtc2V0dGluZ3MtcGFnZS5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFLGNBQUE7RUFDQSx1QkFBQTtFQUNBLGlCQUFBO0VBQ0EsY0FBQTtBQURGOztBQUlBO0VBQ0U7SUFDRSx1QkFBQTtFQURGO0FBQ0YiLCJmaWxlIjoicHJvZmlsZS1zZXR0aW5ncy1wYWdlLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCBcIi4uLy4uLy4uLy4uLy4uL2Fzc2V0cy9zdHlsZXMvdmFyaWFibGVzXCI7XG5cbjpob3N0IHtcbiAgZGlzcGxheTogYmxvY2s7XG4gIHBhZGRpbmc6IDIwcHggMjBweCAzMnB4O1xuICBtYXgtd2lkdGg6IDEwODBweDtcbiAgbWFyZ2luOiAwIGF1dG87XG59XG5cbkBtZWRpYSAobWF4LXdpZHRoOiAkbXEtd2lkdGgtdGFibGV0KSB7XG4gIDpob3N0IHtcbiAgICBwYWRkaW5nOiAyMHB4IDE1cHggMzJweDtcbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/modules/profile/pages/profile-settings-page/profile-settings-page.component.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,uBAAA;EACA,iBAAA;EACA,cAAA;AADF;;AAIA;EACE;IACE,uBAAA;EADF;AACF;AACA,4rBAA4rB","sourcesContent":["@import \"../../../../../assets/styles/variables\";\n\n:host {\n  display: block;\n  padding: 20px 20px 32px;\n  max-width: 1080px;\n  margin: 0 auto;\n}\n\n@media (max-width: $mq-width-tablet) {\n  :host {\n    padding: 20px 15px 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
