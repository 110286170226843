import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { createPayload } from "@shared/utils/store";
import { CreateActionType } from "../../../../store/types/action.types";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IBidsEntityResponse,
  IBidsEntityType,
  IGetBidsEntity,
  ISortBidsEntity,
} from "@shared/modules/bids-entity/interfaces";

enum ACTION_TYPES {
  GET_BIDS = "[BIDS ENTITY] get bids entity",
  GET_BIDS_ERROR = "[BIDS ENTITY] get bids entity error",
  GET_BIDS_SUCCESS = "[BIDS ENTITY] get bids entity success",

  SORT_BIDS = "[BIDS ENTITY] sort bids entity",
  SORT_BIDS_ERROR = "[BIDS ENTITY] sort bids entity error",
  SORT_BIDS_SUCCESS = "[BIDS ENTITY] sort bids entity success",

  TOGGLE_BIDS = "[BIDS ENTITY] toggle bids entity",
  TOGGLE_BIDS_SUCCESS = "[BIDS ENTITY] toggle bids entity success",

  RESET_BIDS_STATE = "[BIDS ENTITY] reset bids entity state",
}

export const getBidsEntityAction: CreateActionType<
  ACTION_TYPES.GET_BIDS,
  IGetBidsEntity
> = createAction(ACTION_TYPES.GET_BIDS, createPayload<IGetBidsEntity>());
export const getBidsEntityErrorAction: CreateActionType<
  ACTION_TYPES.GET_BIDS_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_BIDS_ERROR, createPayload<IServerError>());
export const getBidsEntitySuccessAction: CreateActionType<
  ACTION_TYPES.GET_BIDS_SUCCESS,
  IBidsEntityResponse
> = createAction(
  ACTION_TYPES.GET_BIDS_SUCCESS,
  createPayload<IBidsEntityResponse>(),
);

export const sortBidsEntityAction: CreateActionType<
  ACTION_TYPES.SORT_BIDS,
  ISortBidsEntity
> = createAction(ACTION_TYPES.SORT_BIDS, createPayload<ISortBidsEntity>());
export const sortBidsEntityErrorAction: CreateActionType<
  ACTION_TYPES.SORT_BIDS_ERROR,
  IServerError
> = createAction(ACTION_TYPES.SORT_BIDS_ERROR, createPayload<IServerError>());
export const sortBidsEntitySuccessAction: CreateActionType<
  ACTION_TYPES.SORT_BIDS_SUCCESS,
  IBidsEntityResponse
> = createAction(
  ACTION_TYPES.SORT_BIDS_SUCCESS,
  createPayload<IBidsEntityResponse>(),
);

export const toggleBidsEntityAction: CreateActionType<
  ACTION_TYPES.TOGGLE_BIDS,
  IBidsEntityType
> = createAction(ACTION_TYPES.TOGGLE_BIDS, createPayload<IBidsEntityType>());
export const toggleBidsEntitySuccessAction: ActionCreator<
  ACTION_TYPES.TOGGLE_BIDS_SUCCESS,
  () => Action<ACTION_TYPES.TOGGLE_BIDS_SUCCESS>
> = createAction(ACTION_TYPES.TOGGLE_BIDS_SUCCESS);

export const resetBidsEntityAction: ActionCreator<
  ACTION_TYPES.RESET_BIDS_STATE,
  () => Action<ACTION_TYPES.RESET_BIDS_STATE>
> = createAction(ACTION_TYPES.RESET_BIDS_STATE);
