import { Params } from "@angular/router";
import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import {
  IGrantsRecords,
  IProductResultsResponse,
} from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

enum ACTION_TYPES {
  GET_QUICK_SEARCH_GRANTS = "[QUICK SEARCH GRANTS]: Get",
  GET_QUICK_SEARCH_GRANTS_SUCCESS = "[QUICK SEARCH GRANTS]: Get success",
  GET_QUICK_SEARCH_GRANTS_ERROR = "[QUICK SEARCH GRANTS]: Get error",

  CHANGE_QUICK_SEARCH_GRANTS_PAGE = "[QUICK SEARCH GRANTS] Change page",
  CHANGE_QUICK_SEARCH_GRANTS_QUERY = "[QUICK SEARCH GRANTS] Change query",

  SET_QUICK_SEARCH_GRANTS_SPINNER_STATE = "[QUICK SEARCH GRANTS]: Set spinner state",
  RESET_QUICK_SEARCH_GRANTS_STATE = "[QUICK SEARCH GRANTS]: Reset state",
}

export const getQuickSearchGrantsAction: ActionCreator<
  ACTION_TYPES.GET_QUICK_SEARCH_GRANTS,
  () => Action<ACTION_TYPES.GET_QUICK_SEARCH_GRANTS>
> = createAction(ACTION_TYPES.GET_QUICK_SEARCH_GRANTS);
export const getQuickSearchGrantsErrorAction: CreateActionType<
  ACTION_TYPES.GET_QUICK_SEARCH_GRANTS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_QUICK_SEARCH_GRANTS_ERROR,
  createPayload<IServerError>(),
);
export const getQuickSearchGrantsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_QUICK_SEARCH_GRANTS_SUCCESS,
  IProductResultsResponse<IGrantsRecords>
> = createAction(
  ACTION_TYPES.GET_QUICK_SEARCH_GRANTS_SUCCESS,
  createPayload<IProductResultsResponse<IGrantsRecords>>(),
);

export const changeQuickSearchGrantsPageAction: CreateActionType<
  ACTION_TYPES.CHANGE_QUICK_SEARCH_GRANTS_PAGE,
  number
> = createAction(
  ACTION_TYPES.CHANGE_QUICK_SEARCH_GRANTS_PAGE,
  createPayload<number>(),
);
export const changeQuickSearchGrantsQueryAction: CreateActionType<
  ACTION_TYPES.CHANGE_QUICK_SEARCH_GRANTS_QUERY,
  Params
> = createAction(
  ACTION_TYPES.CHANGE_QUICK_SEARCH_GRANTS_QUERY,
  createPayload<Params>(),
);
export const setQuickSearchResultsGrantsSpinnerStateAction: CreateActionType<
  ACTION_TYPES.SET_QUICK_SEARCH_GRANTS_SPINNER_STATE,
  boolean
> = createAction(
  ACTION_TYPES.SET_QUICK_SEARCH_GRANTS_SPINNER_STATE,
  createPayload<boolean>(),
);

export const resetQuickSearchResultsGrantsStateAction: ActionCreator<
  ACTION_TYPES.RESET_QUICK_SEARCH_GRANTS_STATE,
  () => Action<ACTION_TYPES.RESET_QUICK_SEARCH_GRANTS_STATE>
> = createAction(ACTION_TYPES.RESET_QUICK_SEARCH_GRANTS_STATE);
