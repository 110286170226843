import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IControlOption } from "@shared/interfaces/forms";
import { IServerError } from "@shared/interfaces/server-error";
import { ICDLRecipients, IRefreshSchedules } from "../../interfaces";

export enum ACTION_TYPES {
  GET = "[CDL REFRESH SCHEDULES]: Get",
  GET_ERROR = "[CDL REFRESH SCHEDULES]: Get Error",
  GET_SUCCESS = "[CDL REFRESH SCHEDULES]: Get Success",

  ADD = "[CDL REFRESH SCHEDULES]: Add",
  ADD_ERROR = "[CDL REFRESH SCHEDULES]: Add error",
  ADD_SUCCESS = "[CDL REFRESH SCHEDULES]: Add success",

  REMOVE = "[CDL REFRESH SCHEDULES]: Remove",
  REMOVE_ERROR = "[CDL REFRESH SCHEDULES]: Remove Error",
  REMOVE_SUCCESS = "[CDL REFRESH SCHEDULES]: Remove Success",

  UPDATE = "[CDL REFRESH SCHEDULES]: Update",
  UPDATE_ERROR = "[CDL REFRESH SCHEDULES]: Update Error",
  UPDATE_SUCCESS = "[CDL REFRESH SCHEDULES]: Update Success",

  GET_RECIPIENTS = "[CDL REFRESH SCHEDULES]: Get recipients",
  GET_RECIPIENTS_ERROR = "[CDL REFRESH SCHEDULES]: Get recipients error",
  GET_RECIPIENTS_SUCCESS = "[CDL REFRESH SCHEDULES]: Get recipients success",

  SYNC_RECIPIENTS = "[CDL REFRESH SCHEDULES]: Sync recipients",
  SYNC_RECIPIENTS_ERROR = "[CDL REFRESH SCHEDULES]: Sync recipients error",
  SYNC_RECIPIENTS_SUCCESS = "[CDL REFRESH SCHEDULES]: Sync recipients success",

  RESET = "[CDL REFRESH SCHEDULES] Reset State",
}

export const getCDLRefreshSchedulesAction: ActionCreator<
  ACTION_TYPES.GET,
  () => Action<ACTION_TYPES.GET>
> = createAction(ACTION_TYPES.GET);
export const getCDLRefreshSchedulesErrorAction: CreateActionType<
  ACTION_TYPES.GET_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_ERROR, createPayload<IServerError>());
export const getCDLRefreshSchedulesSuccessAction: CreateActionType<
  ACTION_TYPES.GET_SUCCESS,
  IRefreshSchedules
> = createAction(ACTION_TYPES.GET_SUCCESS, createPayload<IRefreshSchedules>());

export const addCDLRefreshScheduleAction: CreateActionType<
  ACTION_TYPES.ADD,
  number
> = createAction(ACTION_TYPES.ADD, createPayload<number>());
export const addCDLRefreshScheduleErrorAction: CreateActionType<
  ACTION_TYPES.ADD_ERROR,
  IServerError
> = createAction(ACTION_TYPES.ADD_ERROR, createPayload<IServerError>());
export const addCDLRefreshScheduleSuccessAction: ActionCreator<
  ACTION_TYPES.ADD_SUCCESS,
  () => Action<ACTION_TYPES.ADD_SUCCESS>
> = createAction(ACTION_TYPES.ADD_SUCCESS);

export const removeCDLRefreshScheduleAction: CreateActionType<
  ACTION_TYPES.REMOVE,
  number
> = createAction(ACTION_TYPES.REMOVE, createPayload<number>());
export const removeCDLRefreshScheduleErrorAction: CreateActionType<
  ACTION_TYPES.REMOVE_ERROR,
  IServerError
> = createAction(ACTION_TYPES.REMOVE_ERROR, createPayload<IServerError>());
export const removeCDLRefreshScheduleSuccessAction: ActionCreator<
  ACTION_TYPES.REMOVE_SUCCESS,
  () => Action<ACTION_TYPES.REMOVE_SUCCESS>
> = createAction(ACTION_TYPES.REMOVE_SUCCESS);

export const updateCDLRefreshScheduleAction: CreateActionType<
  ACTION_TYPES.UPDATE,
  boolean
> = createAction(ACTION_TYPES.UPDATE, createPayload<boolean>());
export const updateCDLRefreshScheduleErrorAction: CreateActionType<
  ACTION_TYPES.UPDATE_ERROR,
  IServerError
> = createAction(ACTION_TYPES.UPDATE_ERROR, createPayload<IServerError>());
export const updateCDLRefreshScheduleSuccessAction: ActionCreator<
  ACTION_TYPES.UPDATE_SUCCESS,
  () => Action<ACTION_TYPES.UPDATE_SUCCESS>
> = createAction(ACTION_TYPES.UPDATE_SUCCESS);

export const getCDLRecipientsAction: ActionCreator<
  ACTION_TYPES.GET_RECIPIENTS,
  () => Action<ACTION_TYPES.GET_RECIPIENTS>
> = createAction(ACTION_TYPES.GET_RECIPIENTS);
export const getCDLRecipientsErrorAction: CreateActionType<
  ACTION_TYPES.GET_RECIPIENTS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_RECIPIENTS_ERROR,
  createPayload<IServerError>(),
);
export const getCDLRecipientsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_RECIPIENTS_SUCCESS,
  ICDLRecipients
> = createAction(
  ACTION_TYPES.GET_RECIPIENTS_SUCCESS,
  createPayload<ICDLRecipients>(),
);

export const syncRecipientsAction: CreateActionType<
  ACTION_TYPES.SYNC_RECIPIENTS,
  IControlOption
> = createAction(ACTION_TYPES.SYNC_RECIPIENTS, createPayload<IControlOption>());
export const syncRecipientsErrorAction: CreateActionType<
  ACTION_TYPES.SYNC_RECIPIENTS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.SYNC_RECIPIENTS_ERROR,
  createPayload<IServerError>(),
);
export const syncRecipientsSuccessAction: ActionCreator<
  ACTION_TYPES.SYNC_RECIPIENTS_SUCCESS,
  () => Action<ACTION_TYPES.SYNC_RECIPIENTS_SUCCESS>
> = createAction(ACTION_TYPES.SYNC_RECIPIENTS_SUCCESS);

export const resetRefreshSchedulesStateAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => Action<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
