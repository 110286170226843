import {
  forwardRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";

import { MobileDropDownDirective } from "@ui/drop-down/mobile/mobile-drop-down/directives/mobile-drop-down.directive";

import { CustomEmitter } from "@core/services/custom-emitter";

import { dateToMyDate } from "@shared/utils/converters";

import {
  DEFAULT_MOBILE_DROP_DOWN_PARAMS,
  MobileDropDownParams,
} from "@ui/drop-down/mobile/mobile-drop-down/models";

import { SEGMENT_CUSTOM_EMITTER_EVENTS } from "@modules/segment/constants/targeting-forms";
import { MONTHS } from "@shared/constants/date-and-time";
import { WINDOW_POINTS } from "@ui/view-points/constants/view-points";

import { DATE_TYPE } from "@shared/modules/targeting-criteria-controls/data";
import { DatePipe } from "@angular/common";

@Component({
  selector: "bl-date-control",
  templateUrl: "./date-control.component.html",
  styleUrls: ["./date-control.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateControlComponent),
      multi: true,
    },
  ],
})
export class DateControlComponent
  implements OnInit, OnChanges, OnDestroy, ControlValueAccessor
{
  @ViewChild("datePicker") datePicker: any;
  @ViewChild("mobileDropDown") mobileDropDown: MobileDropDownDirective;

  dateValue: Date;
  min: Date;
  max: Date;

  @Input() set initialDate(value: string) {
    this.writeValue(value);
  }

  @Output() changeDateControlValue: EventEmitter<number> =
    new EventEmitter<number>();
  @Input() type: string;
  @Input() minDate: number;
  @Input() maxDate: number;
  @Input() mobileDatepickerParams: MobileDropDownParams =
    DEFAULT_MOBILE_DROP_DOWN_PARAMS;

  @Input() isAbsolutePosition: boolean = true;
  @Input() position: { top: number; left: number } = { top: 0, left: 0 };
  @Input() isOpen: boolean = false;
  @Input() readonly: boolean = false;

  @Output() dateToggle: EventEmitter<boolean> = new EventEmitter();

  readonly windowPoints: typeof WINDOW_POINTS = WINDOW_POINTS;
  readonly dateTypes: typeof DATE_TYPE = DATE_TYPE;

  private _selectedDate;
  selectedDate;
  private _destroyer$: Subject<void> = new Subject();

  datePickerOptions: any;

  datePickerDate: any;
  formattedDate: string;

  shouldHighlightMonth: boolean = false;
  shouldHighlightYear: boolean = false;

  get shouldShowGoToDaysBtn(): boolean {
    return (
      this.isOpen &&
      !!this.datePicker &&
      (this.datePicker.selectMonth || this.datePicker.selectYear)
    );
  }

  constructor(
    private _customEmitter: CustomEmitter,
    private datePipe: DatePipe,
  ) {}
  focusedDateSelect(event: Event): void {
    if (event["path"] && event["path"][0] && event["path"][0]["outerText"]) {
      const selectedDate: string = event["path"][0]["outerText"];
      if (parseInt(selectedDate, 10) === this.selectedDate.getDate()) {
        this.onDateChange(this.selectedDate);
      }
    }
  }
  javascriptiseDate(date: number): Date {
    return new Date(date);
  }
  ngOnInit(): void {
    this._customEmitter
      .on(SEGMENT_CUSTOM_EMITTER_EVENTS.CLOSE_DATE_PICKERS)
      .pipe(
        takeUntil(this._destroyer$),
        tap(() => this.closeDatePicker()),
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.minDate || changes.maxDate) {
      this.setDatePickerOptions();
    }
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
  }

  public disabledDates = (date: Date): boolean => {
    const hoursRemovedDate: number = date.setHours(0, 0, 0, 0);
    // remove hh:mm:ss to compare only dates; use setHours(0, 0, 0, 0)
    return (
      hoursRemovedDate < this.min.setHours(0, 0, 0, 0) ||
      hoursRemovedDate > this.max.setHours(0, 0, 0, 0)
    );
  };

  setDatePickerOptions(): void {
    if (this.minDate) {
      this.min = this.javascriptiseDate(this.minDate);
    }

    if (this.maxDate) {
      this.max = this.javascriptiseDate(this.maxDate);
    }
  }

  mobileDPStyles(): any {
    this.datePickerOptions.selectorWidth = "100%";
    this.datePickerOptions.selectorHeight = "357px";
    return this.datePickerOptions;
  }

  propagateChange(value: any): void {}

  propagateTouch(): void {}

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouch = fn;
  }

  onDateChange(dateModel: Date): void {
    this.propagateTouch();
    this.propagateChange(dateModel.getTime());
    this.changeDateControlValue.emit(dateModel.getTime());
    this.formattedDate = this.datePipe.transform(dateModel, "MMMM d, y");
    this.toggleDatePicker();
  }

  setDate(date: string): void {
    this._selectedDate = new Date(date);
    this.selectedDate = new Date(date);

    // Methods on Date Object will convert from UTC to users timezone
    // Set minutes to current minutes (UTC) + User local time UTC offset

    this.selectedDate.setMinutes(
      this.selectedDate.getMinutes() + this.selectedDate.getTimezoneOffset(),
    );
    this._selectedDate.setMinutes(
      this.selectedDate.getMinutes() + this.selectedDate.getTimezoneOffset(),
    );

    // const { month, day, year }: any = this.datePickerDate;
    this.formattedDate = this.datePipe.transform(date, "MMMM d, y");
  }

  toggleDatePicker(): void {
    if (this.readonly) {
      return;
    }
    !this.isOpen ? this.openDatePicker() : this.closeDatePicker();
  }

  closeDatePicker(): void {
    if (this.mobileDropDown) {
      this.mobileDropDown.close();
    }
    this.dateToggle.emit(false);
  }

  openDatePicker(): void {
    this._customEmitter.emit(SEGMENT_CUSTOM_EMITTER_EVENTS.CLOSE_DATE_PICKERS);
    this.dateToggle.emit(true);
  }

  writeValue(value: string): void {
    if (value !== void 0) {
      this.setDate(value);
    }
  }

  onClickOutside(): void {
    if (this.isOpen) {
      this.closeDatePicker();
    }
  }
}
