import { Params } from "@angular/router";
import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IBidsRecord } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";
import { IGetViewResultsInstitutionSuccessPayload } from "../../interfaces";

enum ACTION_TYPES {
  GET_VIEW_RESULTS_BIDS = "[VIEW RESULTS BIDS] Get bids",
  GET_VIEW_RESULTS_BIDS_ERROR = "[VIEW RESULTS BIDS] Get bids error",
  GET_VIEW_RESULTS_BIDS_SUCCESS = "[VIEW RESULTS BIDS] Get bids success",

  SET_VIEW_RESULTS_BIDS_SPINNER_STATE = "[VIEW RESULTS BIDS] Set spinner state",

  CHANGE_VIEW_RESULTS_BIDS_PAGE = "[VIEW RESULTS BIDS] Change bids pagination",
  CHANGE_VIEW_RESULTS_BIDS_QUERY = "[VIEW RESULTS BIDS] Change Bids query",

  RESET_VIEW_BIDS_STATE = "[VIEW RESULTS BIDS] Reset state",
}

export const getViewResultsBidsAction: ActionCreator<
  ACTION_TYPES.GET_VIEW_RESULTS_BIDS,
  () => Action<ACTION_TYPES.GET_VIEW_RESULTS_BIDS>
> = createAction(ACTION_TYPES.GET_VIEW_RESULTS_BIDS);
export const getViewResultsBidsErrorAction: CreateActionType<
  ACTION_TYPES.GET_VIEW_RESULTS_BIDS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_VIEW_RESULTS_BIDS_ERROR,
  createPayload<IServerError>(),
);
export const getViewResultsBidsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_VIEW_RESULTS_BIDS_SUCCESS,
  IGetViewResultsInstitutionSuccessPayload<IBidsRecord>
> = createAction(
  ACTION_TYPES.GET_VIEW_RESULTS_BIDS_SUCCESS,
  createPayload<IGetViewResultsInstitutionSuccessPayload<IBidsRecord>>(),
);

export const setViewResultsBidsSpinnerStateAction: CreateActionType<
  ACTION_TYPES.SET_VIEW_RESULTS_BIDS_SPINNER_STATE,
  boolean
> = createAction(
  ACTION_TYPES.SET_VIEW_RESULTS_BIDS_SPINNER_STATE,
  createPayload<boolean>(),
);

export const changeViewResultsBidsPageAction: CreateActionType<
  ACTION_TYPES.CHANGE_VIEW_RESULTS_BIDS_PAGE,
  number
> = createAction(
  ACTION_TYPES.CHANGE_VIEW_RESULTS_BIDS_PAGE,
  createPayload<number>(),
);

export const changeViewResultsBidsQueryAction: CreateActionType<
  ACTION_TYPES.CHANGE_VIEW_RESULTS_BIDS_QUERY,
  Params
> = createAction(
  ACTION_TYPES.CHANGE_VIEW_RESULTS_BIDS_QUERY,
  createPayload<Params>(),
);

export const resetViewResultsBidsStateAction: ActionCreator<
  ACTION_TYPES.RESET_VIEW_BIDS_STATE,
  () => Action<ACTION_TYPES.RESET_VIEW_BIDS_STATE>
> = createAction(ACTION_TYPES.RESET_VIEW_BIDS_STATE);
