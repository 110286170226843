import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { IPrices } from "../../interfaces/price";

enum ACTION_TYPES {
  GET_PRICING_DATA = "[E-COMMERCE]: Get Pricing Data",
  GET_PRICING_DATA_SUCCESS = "[E-COMMERCE]: Get Pricing Data Success",
  GET_PRICING_DATA_ERROR = "[E-COMMERCE]: Get Pricing Data Error",
}

export const getPricingDataAction: ActionCreator<
  ACTION_TYPES.GET_PRICING_DATA,
  () => Action<ACTION_TYPES.GET_PRICING_DATA>
> = createAction(ACTION_TYPES.GET_PRICING_DATA);
export const getPricingDataSuccessAction: CreateActionType<
  ACTION_TYPES.GET_PRICING_DATA_SUCCESS,
  IPrices
> = createAction(
  ACTION_TYPES.GET_PRICING_DATA_SUCCESS,
  createPayload<IPrices>(),
);
export const getPricingDataErrorAction: CreateActionType<
  ACTION_TYPES.GET_PRICING_DATA_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_PRICING_DATA_ERROR,
  createPayload<IServerError>(),
);
