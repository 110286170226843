import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { IViewOverviewResult } from "../../../view/interfaces";

enum ACTION_TYPES {
  GET_VIEW_OVERVIEW = "[VIEW OVERVIEW] Get view overview",
  GET_VIEW_OVERVIEW_SUCCESS = "[VIEW OVERVIEW] Get view overview success",
  GET_VIEW_OVERVIEW_ERROR = "[VIEW OVERVIEW] Get view overview error",

  RESET_VIEW_OVERVIEW_STATE = "[VIEW OVERVIEW] Reset state",
}

export const getViewOverviewAction: ActionCreator<
  ACTION_TYPES.GET_VIEW_OVERVIEW,
  () => Action<ACTION_TYPES.GET_VIEW_OVERVIEW>
> = createAction(ACTION_TYPES.GET_VIEW_OVERVIEW);
export const getViewOverviewSuccessAction: CreateActionType<
  ACTION_TYPES.GET_VIEW_OVERVIEW_SUCCESS,
  IViewOverviewResult
> = createAction(
  ACTION_TYPES.GET_VIEW_OVERVIEW_SUCCESS,
  createPayload<IViewOverviewResult>(),
);
export const getViewOverviewErrorAction: CreateActionType<
  ACTION_TYPES.GET_VIEW_OVERVIEW_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_VIEW_OVERVIEW_ERROR,
  createPayload<IServerError>(),
);

export const resetViewResultsStateAction: ActionCreator<
  ACTION_TYPES.RESET_VIEW_OVERVIEW_STATE,
  () => Action<ACTION_TYPES.RESET_VIEW_OVERVIEW_STATE>
> = createAction(ACTION_TYPES.RESET_VIEW_OVERVIEW_STATE);
