// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: inline-block;
  height: 20px;
  width: 20px;
  align-items: center;
}
:host:not(.is-white) {
  background-size: 100%;
}
:host.is-white {
  background-size: 100%;
}

kendo-loader {
  color: #16A99B;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNwaW5uZXIuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxxQkFBQTtFQUNBLFlBQUE7RUFDQSxXQUFBO0VBQ0EsbUJBQUE7QUFDRjtBQUNFO0VBRUUscUJBQUE7QUFBSjtBQUdFO0VBRUUscUJBQUE7QUFGSjs7QUFNQTtFQUNFLGNBQUE7QUFIRiIsImZpbGUiOiJzcGlubmVyLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICBkaXNwbGF5OiBpbmxpbmUtYmxvY2s7XG4gIGhlaWdodDogMjBweDtcbiAgd2lkdGg6IDIwcHg7XG4gIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG5cbiAgJjpub3QoLmlzLXdoaXRlKSB7XG4gICAgLy8gYmFja2dyb3VuZDogdXJsKCcuLi8uLi8uLi8uLi8uLi9hc3NldHMvaW1nL2JsdWUtc3Bpbm5lci5zdmcnKSBuby1yZXBlYXQgNTAlIDUwJTtcbiAgICBiYWNrZ3JvdW5kLXNpemU6IDEwMCU7XG4gIH1cblxuICAmLmlzLXdoaXRlIHtcbiAgICAvLyBiYWNrZ3JvdW5kOiB1cmwoJy4uLy4uLy4uLy4uLy4uL2Fzc2V0cy9pbWcvd2hpdGUtc3Bpbm5lci5zdmcnKSBuby1yZXBlYXQgNTAlIDUwJTtcbiAgICBiYWNrZ3JvdW5kLXNpemU6IDEwMCU7XG4gIH1cbn1cblxua2VuZG8tbG9hZGVye1xuICBjb2xvcjogIzE2QTk5Qjtcbn0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/ui/spinner/components/spinner/spinner.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;AACF;AACE;EAEE,qBAAA;AAAJ;AAGE;EAEE,qBAAA;AAFJ;;AAMA;EACE,cAAA;AAHF;AACA,w6BAAw6B","sourcesContent":[":host {\n  display: inline-block;\n  height: 20px;\n  width: 20px;\n  align-items: center;\n\n  &:not(.is-white) {\n    // background: url('../../../../../assets/img/blue-spinner.svg') no-repeat 50% 50%;\n    background-size: 100%;\n  }\n\n  &.is-white {\n    // background: url('../../../../../assets/img/white-spinner.svg') no-repeat 50% 50%;\n    background-size: 100%;\n  }\n}\n\nkendo-loader{\n  color: #16A99B;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
