import { SafeHtml } from "@angular/platform-browser";
import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { ITermsOfUsePageData } from "../../interfaces/terms-of-servise";

export enum ACTION_TYPES {
  GET_PAGE_DATA = "[BROKERS TOU] Get terms of use data",
  GET_PAGE_DATA_ERROR = "[BROKERS TOU] Get terms of use data error",
  GET_PAGE_DATA_SUCCESS = "[BROKERS TOU] Get terms of use data success",
}

export const getBrokerTermsOfUseAction: ActionCreator<
  ACTION_TYPES.GET_PAGE_DATA,
  () => Action<ACTION_TYPES.GET_PAGE_DATA>
> = createAction(ACTION_TYPES.GET_PAGE_DATA);
export const getBrokerTermsOfUseErrorAction: CreateActionType<
  ACTION_TYPES.GET_PAGE_DATA_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_PAGE_DATA_ERROR,
  createPayload<IServerError>(),
);
export const getBrokerTermsOfUseSuccessAction: CreateActionType<
  ACTION_TYPES.GET_PAGE_DATA_SUCCESS,
  ITermsOfUsePageData<SafeHtml>
> = createAction(
  ACTION_TYPES.GET_PAGE_DATA_SUCCESS,
  createPayload<ITermsOfUsePageData<SafeHtml>>(),
);
