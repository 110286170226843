// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  display: block;
  padding: 20px 20px 92px 20px;
  max-width: 1080px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .wrapper {
    padding: 20px 15px 62px 15px;
  }
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInByb2ZpbGUtZGV0YWlscy1wYWdlLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0UsY0FBQTtFQUNBLDRCQUFBO0VBQ0EsaUJBQUE7RUFDQSxjQUFBO0FBREY7O0FBSUE7RUFDRTtJQUNFLDRCQUFBO0VBREY7QUFDRiIsImZpbGUiOiJwcm9maWxlLWRldGFpbHMtcGFnZS5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgJy4uLy4uLy4uLy4uLy4uL2Fzc2V0cy9zdHlsZXMvdmFyaWFibGVzJztcblxuLndyYXBwZXIge1xuICBkaXNwbGF5OiBibG9jaztcbiAgcGFkZGluZzogMjBweCAyMHB4IDkycHggMjBweDtcbiAgbWF4LXdpZHRoOiAxMDgwcHg7XG4gIG1hcmdpbjogMCBhdXRvO1xufVxuXG5AbWVkaWEgKG1heC13aWR0aDogJG1xLXdpZHRoLXRhYmxldCkge1xuICAud3JhcHBlciB7XG4gICAgcGFkZGluZzogMjBweCAxNXB4IDYycHggMTVweDtcbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/modules/profile/pages/profile-details-page/profile-details-page.component.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,4BAAA;EACA,iBAAA;EACA,cAAA;AADF;;AAIA;EACE;IACE,4BAAA;EADF;AACF;AACA,4sBAA4sB","sourcesContent":["@import '../../../../../assets/styles/variables';\n\n.wrapper {\n  display: block;\n  padding: 20px 20px 92px 20px;\n  max-width: 1080px;\n  margin: 0 auto;\n}\n\n@media (max-width: $mq-width-tablet) {\n  .wrapper {\n    padding: 20px 15px 62px 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
