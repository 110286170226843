import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { createPayload } from "@shared/utils/store";
import { CreateActionType } from "../../../../store/types/action.types";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IGetGrantsEntity,
  IGrantsEntityResponse,
  IGrantsEntityType,
  ISortGrantsEntity,
} from "@shared/modules/grants-entity/interfaces";

enum ACTION_TYPES {
  GET_GRANTS = "[GRANTS ENTITY] get district entity",
  GET_GRANTS_ERROR = "[GRANTS ENTITY] get grants entity error",
  GET_GRANTS_SUCCESS = "[GRANTS ENTITY] get grants entity success",

  SORT_GRANTS = "[GRANTS ENTITY] sort grants entity",
  SORT_GRANTS_ERROR = "[GRANTS ENTITY] sort grants entity error",
  SORT_GRANTS_SUCCESS = "[GRANTS ENTITY] sort grants entity success",

  TOGGLE_GRANTS = "[GRANTS ENTITY] toggle grants entity",
  TOGGLE_GRANTS_SUCCESS = "[GRANTS ENTITY] toggle grants entity success",

  RESET_GRANTS_STATE = "[GRANTS ENTITY] reset grants entity state",
}

export const getGrantsEntityAction: CreateActionType<
  ACTION_TYPES.GET_GRANTS,
  IGetGrantsEntity
> = createAction(ACTION_TYPES.GET_GRANTS, createPayload<IGetGrantsEntity>());
export const getGrantsEntityErrorAction: CreateActionType<
  ACTION_TYPES.GET_GRANTS_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_GRANTS_ERROR, createPayload<IServerError>());
export const getGrantsEntitySuccessAction: CreateActionType<
  ACTION_TYPES.GET_GRANTS_SUCCESS,
  IGrantsEntityResponse
> = createAction(
  ACTION_TYPES.GET_GRANTS_SUCCESS,
  createPayload<IGrantsEntityResponse>(),
);

export const sortGrantsEntityAction: CreateActionType<
  ACTION_TYPES.SORT_GRANTS,
  ISortGrantsEntity
> = createAction(ACTION_TYPES.SORT_GRANTS, createPayload<ISortGrantsEntity>());
export const sortGrantsEntityErrorAction: CreateActionType<
  ACTION_TYPES.SORT_GRANTS_ERROR,
  IServerError
> = createAction(ACTION_TYPES.SORT_GRANTS_ERROR, createPayload<IServerError>());
export const sortGrantsEntitySuccessAction: CreateActionType<
  ACTION_TYPES.SORT_GRANTS_SUCCESS,
  IGrantsEntityResponse
> = createAction(
  ACTION_TYPES.SORT_GRANTS_SUCCESS,
  createPayload<IGrantsEntityResponse>(),
);

export const toggleGrantsEntityAction: CreateActionType<
  ACTION_TYPES.TOGGLE_GRANTS,
  IGrantsEntityType
> = createAction(
  ACTION_TYPES.TOGGLE_GRANTS,
  createPayload<IGrantsEntityType>(),
);
export const toggleGrantsEntitySuccessAction: ActionCreator<
  ACTION_TYPES.TOGGLE_GRANTS_SUCCESS,
  () => Action<ACTION_TYPES.TOGGLE_GRANTS_SUCCESS>
> = createAction(ACTION_TYPES.TOGGLE_GRANTS_SUCCESS);

export const resetGrantsEntityAction: ActionCreator<
  ACTION_TYPES.RESET_GRANTS_STATE,
  () => Action<ACTION_TYPES.RESET_GRANTS_STATE>
> = createAction(ACTION_TYPES.RESET_GRANTS_STATE);
