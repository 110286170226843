import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { IResultsCounter } from "../../interfaces";

enum ACTION_TYPES {
  GET = "[VIEW RESULTS PERSONNEL COUNTERS] Get personnel counters",
  GET_ERROR = "[VIEW RESULTS PERSONNEL COUNTERS] Get personnel counters error",
  GET_SUCCESS = "[VIEW RESULTS PERSONNEL COUNTERS] Get personnel counters success",

  SET_SPINNER_STATE = "[VIEW RESULTS PERSONNEL COUNTERS] Set spinner state",

  RESET = "[VIEW RESULTS PERSONNEL COUNTERS] Reset personnel counters",
}

export const getViewResultsPersonnelCountersAction: ActionCreator<
  ACTION_TYPES.GET,
  () => Action<ACTION_TYPES.GET>
> = createAction(ACTION_TYPES.GET);
export const getViewResultsPersonnelCountersErrorAction: CreateActionType<
  ACTION_TYPES.GET_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_ERROR, createPayload<IServerError>());
export const getViewResultsPersonnelCountersSuccessAction: CreateActionType<
  ACTION_TYPES.GET_SUCCESS,
  IResultsCounter
> = createAction(ACTION_TYPES.GET_SUCCESS, createPayload<IResultsCounter>());

export const setViewResultsPersonnelCountersSpinnerStateAction: CreateActionType<
  ACTION_TYPES.SET_SPINNER_STATE,
  boolean
> = createAction(ACTION_TYPES.SET_SPINNER_STATE, createPayload<boolean>());

export const resetViewResultsPersonnelCountersAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => Action<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
