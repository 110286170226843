// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[disabled] + .fake-checkbox {
  opacity: 0.3;
  cursor: default;
}

.content {
  margin-left: 5px;
  position: relative;
  top: 1px;
}

.disabled .content {
  cursor: default;
  opacity: 0.5;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImN1c3RvbS1jaGVja2JveC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLFlBQUE7RUFDQSxlQUFBO0FBQ0Y7O0FBRUE7RUFDRSxnQkFBQTtFQUNBLGtCQUFBO0VBQ0EsUUFBQTtBQUNGOztBQUdFO0VBQ0UsZUFBQTtFQUNBLFlBQUE7QUFBSiIsImZpbGUiOiJjdXN0b20tY2hlY2tib3guY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJpbnB1dFtkaXNhYmxlZF0gKyAuZmFrZS1jaGVja2JveCB7XG4gIG9wYWNpdHk6IDAuMztcbiAgY3Vyc29yOiBkZWZhdWx0O1xufVxuXG4uY29udGVudCB7XG4gIG1hcmdpbi1sZWZ0OiA1cHg7XG4gIHBvc2l0aW9uOiByZWxhdGl2ZTtcbiAgdG9wOiAxcHg7XG59XG5cbi5kaXNhYmxlZCB7XG4gIC5jb250ZW50IHtcbiAgICBjdXJzb3I6IGRlZmF1bHQ7XG4gICAgb3BhY2l0eTogMC41O1xuICB9XG59XG4iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/ui/custom-controls/components/custom-checkbox/custom-checkbox.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,eAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;EACA,QAAA;AACF;;AAGE;EACE,eAAA;EACA,YAAA;AAAJ;AACA,grBAAgrB","sourcesContent":["input[disabled] + .fake-checkbox {\n  opacity: 0.3;\n  cursor: default;\n}\n\n.content {\n  margin-left: 5px;\n  position: relative;\n  top: 1px;\n}\n\n.disabled {\n  .content {\n    cursor: default;\n    opacity: 0.5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
