import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Renderer2,
  TemplateRef,
} from "@angular/core";

import { MobileDropDownContentDirective } from "@ui/drop-down/mobile/mobile-drop-down/directives/mobile-drop-down-content.directive";

import {
  AnimationState,
  ANIMATION_END,
  ANIMATION_START,
  CONTENT_POSITION,
  MOBILE_DROPDOWN_TEMPLATE_TOKEN,
} from "../../models";

import { slideEffect } from "@ui/animations/slide";

@Component({
  selector: "bl-mobile-portal",
  templateUrl: "./mobile-portal.component.html",
  styleUrls: ["./mobile-portal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    "(@slideEffect.start)": "this.onAnimationStart()",
    "(@slideEffect.done)": "this.onAnimationEnd()",
  },
  animations: [slideEffect()],
})
export class MobilePortalComponent implements AfterContentInit {
  @HostBinding("@slideEffect") get state(): AnimationState {
    return this.animationState;
  }

  constructor(
    public elementRef: ElementRef<any>,
    public animationState: AnimationState,
    public renderer: Renderer2,
    @Inject(MOBILE_DROPDOWN_TEMPLATE_TOKEN)
    public mobileDropDownTemplateRef: TemplateRef<MobileDropDownContentDirective>,
    @Inject(ANIMATION_START) public onAnimationStart: () => void,
    @Inject(ANIMATION_END) public onAnimationEnd: () => void,
  ) {}

  ngAfterContentInit(): void {
    const timer: number = setTimeout(() => {
      this.animationState = CONTENT_POSITION.INITIAL;
      clearTimeout(timer);
    });
  }
}
