export enum GEO_CODES_TYPES {
  UNIT_TYPE_ZIP = 5,
  UNIT_TYPE_SCF = 6,
}

// below error codes are only for reference, contact BE if any changes are needed
export const WITHOUT_SEAT_ERROR_TYPE = 3;
export const INVALID_ERROR_TYPE = 1;
export const OVERLAPS_ERROR_TYPE = 2;

export const ADD_CODES_SUCCESS_MSG: string = "All changes saved";

import { IPerPages } from "@shared/constants/pagination";

export const DEFAULT_CODES_OFFSET: number = 0;
export const DEFAULT_CODES_LIMIT: number = 30;
export const MULTI_CODES_PAGES: IPerPages = [30, 60, 90, 120];

export const NO_RECORDS_MSG: string =
  " There are no Records within your criteria. Consider broadening your criteria.";
