import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { IUpdateKeyword, IUpdateKeywordId } from "../../interfaces/segment";

import {
  IChangeTargetingCriteria,
  IOnSegmentUpdateDataControls,
} from "@shared/modules/targeting-criteria-controls/data";

enum ACTION_TYPES {
  ON_UPDATE_BIDS = "[BIDS] On Update BIDS targeting",

  UPDATE_BIDS = "[BIDS] Update BIDS targeting",
  UPDATE_BIDS_ERROR = "[BIDS] Update BIDS targeting error",
  UPDATE_BIDS_SUCCESS = "[BIDS] Update BIDS targeting success",

  ADD_BIDS_KEYWORD = "[BIDS]: Add keyword",
  ADD_BIDS_KEYWORD_ERROR = "[BIDS]: Add keyword error",
  ADD_BIDS_KEYWORD_SUCCESS = "[BIDS]: Add keyword success",

  GET_BIDS_KEYWORDS = "[BIDS] Get keywords",
  GET_BIDS_KEYWORDS_ERROR = "[BIDS] Get keywords error",
  GET_BIDS_KEYWORDS_SUCCESS = "[BIDS] Get keywords success",

  UPDATE_BIDS_KEYWORD = "[BIDS]: Update keyword",
  UPDATE_BIDS_KEYWORD_ERROR = "[BIDS]: Update keyword error",
  UPDATE_BIDS_KEYWORD_SUCCESS = "[BIDS]: Update keyword success",

  REMOVE_BIDS_KEYWORD = "[BIDS]: Remove keyword",
  REMOVE_BIDS_KEYWORD_ERROR = "[BIDS]: Remove keyword error",
  REMOVE_BIDS_KEYWORD_SUCCESS = "[BIDS]: Remove keyword success",

  RESET_BIDS_KEYWORDS = "[BIDS]: Reset Bids keywords",

  TOGGLE_IS_ALL_BIDS = "[BIDS] toggle BIDS targeting is all",
  TOGGLE_IS_ALL_BIDS_ERROR = "[BIDS] toggle BIDS targeting is all error",
  TOGGLE_IS_ALL_BIDS_SUCCESS = "[BIDS] toggle BIDS targeting is all success",

  TOGGLE_INCLUDE_BIDS = "[Segment] toggle BIDS include",

  BIDS_RESET = "[BIDS]: Bids reset",
}

export const onUpdateBidsTargetingAction: CreateActionType<
  ACTION_TYPES.ON_UPDATE_BIDS,
  IOnSegmentUpdateDataControls
> = createAction(
  ACTION_TYPES.ON_UPDATE_BIDS,
  createPayload<IOnSegmentUpdateDataControls>(),
);

export const updateBidsTargetingAction: CreateActionType<
  ACTION_TYPES.UPDATE_BIDS,
  IChangeTargetingCriteria
> = createAction(
  ACTION_TYPES.UPDATE_BIDS,
  createPayload<IChangeTargetingCriteria>(),
);
export const updateBidsTargetingErrorAction: CreateActionType<
  ACTION_TYPES.UPDATE_BIDS_ERROR,
  IServerError
> = createAction(ACTION_TYPES.UPDATE_BIDS_ERROR, createPayload<IServerError>());
export const updateBidsTargetingSuccessAction: ActionCreator<
  ACTION_TYPES.UPDATE_BIDS_SUCCESS,
  () => Action<ACTION_TYPES.UPDATE_BIDS_SUCCESS>
> = createAction(ACTION_TYPES.UPDATE_BIDS_SUCCESS);

export const addBidsKeywordAction: CreateActionType<
  ACTION_TYPES.ADD_BIDS_KEYWORD,
  IUpdateKeyword
> = createAction(
  ACTION_TYPES.ADD_BIDS_KEYWORD,
  createPayload<IUpdateKeyword>(),
);
export const addBidsKeywordErrorAction: CreateActionType<
  ACTION_TYPES.ADD_BIDS_KEYWORD_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.ADD_BIDS_KEYWORD_ERROR,
  createPayload<IServerError>(),
);
export const addBidsKeywordSuccessAction: ActionCreator<
  ACTION_TYPES.ADD_BIDS_KEYWORD_SUCCESS,
  () => Action<ACTION_TYPES.ADD_BIDS_KEYWORD_SUCCESS>
> = createAction(ACTION_TYPES.ADD_BIDS_KEYWORD_SUCCESS);

export const getBidsKeywordsAction: CreateActionType<
  ACTION_TYPES.GET_BIDS_KEYWORDS,
  string
> = createAction(ACTION_TYPES.GET_BIDS_KEYWORDS, createPayload<string>());
export const getBidsKeywordsErrorAction: CreateActionType<
  ACTION_TYPES.GET_BIDS_KEYWORDS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_BIDS_KEYWORDS_ERROR,
  createPayload<IServerError>(),
);
export const getBidsKeywordsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_BIDS_KEYWORDS_SUCCESS,
  string[]
> = createAction(
  ACTION_TYPES.GET_BIDS_KEYWORDS_SUCCESS,
  createPayload<string[]>(),
);

export const updateBidsKeywordAction: CreateActionType<
  ACTION_TYPES.UPDATE_BIDS_KEYWORD,
  IUpdateKeyword
> = createAction(
  ACTION_TYPES.UPDATE_BIDS_KEYWORD,
  createPayload<IUpdateKeyword>(),
);
export const updateBidsKeywordErrorAction: CreateActionType<
  ACTION_TYPES.UPDATE_BIDS_KEYWORD_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.UPDATE_BIDS_KEYWORD_ERROR,
  createPayload<IServerError>(),
);
export const updateBidsKeywordSuccessAction: ActionCreator<
  ACTION_TYPES.UPDATE_BIDS_KEYWORD_SUCCESS,
  () => Action<ACTION_TYPES.UPDATE_BIDS_KEYWORD_SUCCESS>
> = createAction(ACTION_TYPES.UPDATE_BIDS_KEYWORD_SUCCESS);

export const removeBidsKeywordAction: CreateActionType<
  ACTION_TYPES.REMOVE_BIDS_KEYWORD,
  IUpdateKeywordId
> = createAction(
  ACTION_TYPES.REMOVE_BIDS_KEYWORD,
  createPayload<IUpdateKeywordId>(),
);
export const removeBidsKeywordErrorAction: CreateActionType<
  ACTION_TYPES.REMOVE_BIDS_KEYWORD_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.REMOVE_BIDS_KEYWORD_ERROR,
  createPayload<IServerError>(),
);
export const removeBidsKeywordSuccessAction: ActionCreator<
  ACTION_TYPES.REMOVE_BIDS_KEYWORD_SUCCESS,
  () => Action<ACTION_TYPES.REMOVE_BIDS_KEYWORD_SUCCESS>
> = createAction(ACTION_TYPES.REMOVE_BIDS_KEYWORD_SUCCESS);

export const resetBidsKeywordsAction: ActionCreator<
  ACTION_TYPES.RESET_BIDS_KEYWORDS,
  () => Action<ACTION_TYPES.RESET_BIDS_KEYWORDS>
> = createAction(ACTION_TYPES.RESET_BIDS_KEYWORDS);

export const toggleIncludeBidsAction: ActionCreator<
  ACTION_TYPES.TOGGLE_INCLUDE_BIDS,
  () => Action<ACTION_TYPES.TOGGLE_INCLUDE_BIDS>
> = createAction(ACTION_TYPES.TOGGLE_INCLUDE_BIDS);

export const bidsResetAction: ActionCreator<
  ACTION_TYPES.BIDS_RESET,
  () => Action<ACTION_TYPES.BIDS_RESET>
> = createAction(ACTION_TYPES.BIDS_RESET);

// TODO Check is nex actions used
// export const toggleIsAllBidsTargetingAction: CreateActionType<ACTION_TYPES.TOGGLE_IS_ALL_BIDS, any> = createAction(ACTION_TYPES.TOGGLE_IS_ALL_BIDS, createPayload<any>());
// export const toggleIsAllBidsTargetingErrorAction: CreateActionType<ACTION_TYPES.TOGGLE_IS_ALL_BIDS_ERROR, IServerError> = createAction(ACTION_TYPES.TOGGLE_IS_ALL_BIDS_ERROR, createPayload<IServerError>());
// export const toggleIsAllBidsTargetingSuccessAction: ActionCreator<ACTION_TYPES.TOGGLE_IS_ALL_BIDS_SUCCESS, () => TypedAction<ACTION_TYPES.TOGGLE_IS_ALL_BIDS_SUCCESS>> = createAction(ACTION_TYPES.TOGGLE_IS_ALL_BIDS_SUCCESS);
