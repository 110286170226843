import { Params } from "@angular/router";
import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import {
  IInstitutionsRecords,
  IProductResultsResponse,
} from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";
import { IToggleInstitutionRecordPayload } from "../../interfaces";

enum ACTION_TYPES {
  GET_VIEW_RESULTS_INSTITUTIONS_DISTRICTS = "[VIEW RESULTS INSTITUTIONS DISTRICTS] Get institution districts",
  GET_VIEW_RESULTS_INSTITUTIONS_DISTRICTS_ERROR = "[VIEW RESULTS INSTITUTIONS DISTRICTS] Get institution districts error",
  GET_VIEW_RESULTS_INSTITUTIONS_DISTRICTS_SUCCESS = "[VIEW RESULTS INSTITUTIONS DISTRICTS] Get institution districts success",

  TOGGLE_VIEW_RESULTS_INSTITUTIONS_DISTRICT = "[VIEW RESULTS INSTITUTIONS DISTRICTS] Toggle",

  SET_VIEW_RESULTS_INSTITUTIONS_DISTRICTS_SPINNER_STATE = "[VIEW RESULTS INSTITUTIONS DISTRICTS] Set spinner state",

  CHANGE_VIEW_RESULTS_INSTITUTIONS_DISTRICTS_PAGE = "[VIEW RESULTS INSTITUTIONS DISTRICTS] Change page",
  CHANGE_VIEW_RESULTS_INSTITUTIONS_DISTRICTS_QUERY = "[VIEW RESULTS INSTITUTIONS DISTRICTS] Change query",

  RESET_SELECTED = "[VIEW RESULTS INSTITUTIONS DISTRICTS] Reset selected institution districts",

  RESET_VIEW_RESULTS_INSTITUTIONS_DISTRICTS_PAGE_STATE = "[VIEW RESULTS INSTITUTIONS DISTRICTS] Reset state",
}

export const getViewResultsInstitutionDistrictsAction: ActionCreator<
  ACTION_TYPES.GET_VIEW_RESULTS_INSTITUTIONS_DISTRICTS,
  () => Action<ACTION_TYPES.GET_VIEW_RESULTS_INSTITUTIONS_DISTRICTS>
> = createAction(ACTION_TYPES.GET_VIEW_RESULTS_INSTITUTIONS_DISTRICTS);
export const getViewResultsInstitutionDistrictsErrorAction: CreateActionType<
  ACTION_TYPES.GET_VIEW_RESULTS_INSTITUTIONS_DISTRICTS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_VIEW_RESULTS_INSTITUTIONS_DISTRICTS_ERROR,
  createPayload<IServerError>(),
);
export const getViewResultsInstitutionDistrictsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_VIEW_RESULTS_INSTITUTIONS_DISTRICTS_SUCCESS,
  IProductResultsResponse<IInstitutionsRecords>
> = createAction(
  ACTION_TYPES.GET_VIEW_RESULTS_INSTITUTIONS_DISTRICTS_SUCCESS,
  createPayload<IProductResultsResponse<IInstitutionsRecords>>(),
);

export const toggleViewResultsInstitutionDistrictsAction: CreateActionType<
  ACTION_TYPES.TOGGLE_VIEW_RESULTS_INSTITUTIONS_DISTRICT,
  IToggleInstitutionRecordPayload
> = createAction(
  ACTION_TYPES.TOGGLE_VIEW_RESULTS_INSTITUTIONS_DISTRICT,
  createPayload<IToggleInstitutionRecordPayload>(),
);

export const setViewResultsInstitutionDistrictsSpinnerStateAction: CreateActionType<
  ACTION_TYPES.SET_VIEW_RESULTS_INSTITUTIONS_DISTRICTS_SPINNER_STATE,
  boolean
> = createAction(
  ACTION_TYPES.SET_VIEW_RESULTS_INSTITUTIONS_DISTRICTS_SPINNER_STATE,
  createPayload<boolean>(),
);

export const changeViewResultsInstitutionDistrictsPageAction: CreateActionType<
  ACTION_TYPES.CHANGE_VIEW_RESULTS_INSTITUTIONS_DISTRICTS_PAGE,
  number
> = createAction(
  ACTION_TYPES.CHANGE_VIEW_RESULTS_INSTITUTIONS_DISTRICTS_PAGE,
  createPayload<number>(),
);

export const changeViewResultsInstitutionDistrictsQueryAction: CreateActionType<
  ACTION_TYPES.CHANGE_VIEW_RESULTS_INSTITUTIONS_DISTRICTS_QUERY,
  Params
> = createAction(
  ACTION_TYPES.CHANGE_VIEW_RESULTS_INSTITUTIONS_DISTRICTS_QUERY,
  createPayload<Params>(),
);

export const resetSelectedInstitutionDistrictsAction: ActionCreator<
  ACTION_TYPES.RESET_SELECTED,
  () => Action<ACTION_TYPES.RESET_SELECTED>
> = createAction(ACTION_TYPES.RESET_SELECTED);

export const resetViewResultsInstitutionDistrictsStateAction: ActionCreator<
  ACTION_TYPES.RESET_VIEW_RESULTS_INSTITUTIONS_DISTRICTS_PAGE_STATE,
  () => Action<ACTION_TYPES.RESET_VIEW_RESULTS_INSTITUTIONS_DISTRICTS_PAGE_STATE>
> = createAction(
  ACTION_TYPES.RESET_VIEW_RESULTS_INSTITUTIONS_DISTRICTS_PAGE_STATE,
);
