import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

enum ACTION_TYPES {
  CHANGE_SORT_DIRECTION = "[DASHBOARD VIEWS]: Change sort direction",
  CHANGE_SORT_BY = "[DASHBOARD VIEWS]: Change sort by",

  SET_SORT_IS_OPEN_STATE = "[DASHBOARD VIEWS] Set sort is open state",
  RESET = "[DASHBOARD VIEWS]: Reset sorting",
}

export const changeDashboardViewsSortDirectionAction: CreateActionType<
  ACTION_TYPES.CHANGE_SORT_DIRECTION,
  string
> = createAction(ACTION_TYPES.CHANGE_SORT_DIRECTION, createPayload<string>());
export const changeDashboardViewsSortByAction: CreateActionType<
  ACTION_TYPES.CHANGE_SORT_BY,
  string
> = createAction(ACTION_TYPES.CHANGE_SORT_BY, createPayload<string>());

export const setSortIsOpenStateAction: CreateActionType<
  ACTION_TYPES.SET_SORT_IS_OPEN_STATE,
  boolean
> = createAction(ACTION_TYPES.SET_SORT_IS_OPEN_STATE, createPayload<boolean>());

export const resetDashboardViewsSortingAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => Action<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
