// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  display: block;
  position: relative;
}
.wrapper .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  height: 90px;
  width: 90px;
  color: white;
  background-size: 100%;
}
.wrapper .spinner .k-loader-primary {
  color: #0078A7;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImN1c3RvbS1zcGlubmVyLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0UsY0FBQTtFQUNBLGtCQUFBO0FBREY7QUFHRTtFQUNFLGtCQUFBO0VBQ0EsUUFBQTtFQUNBLFNBQUE7RUFDQSxxQ0FBQTtFQUNBLFlBQUE7RUFDQSxXQUFBO0VBQ0EsWUFBQTtFQUVBLHFCQUFBO0FBRko7QUFJSTtFQUNFLGNBQUE7QUFGTiIsImZpbGUiOiJjdXN0b20tc3Bpbm5lci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgJy4uLy4uLy4uLy4uLy4uL2Fzc2V0cy9zdHlsZXMvdmFyaWFibGVzJztcblxuLndyYXBwZXIge1xuICBkaXNwbGF5OiBibG9jaztcbiAgcG9zaXRpb246IHJlbGF0aXZlO1xuXG4gIC5zcGlubmVyIHtcbiAgICBwb3NpdGlvbjogYWJzb2x1dGU7XG4gICAgdG9wOiA1MCU7XG4gICAgbGVmdDogNTAlO1xuICAgIHRyYW5zZm9ybTogdHJhbnNsYXRlM2QoLTUwJSwgLTUwJSwgMCk7XG4gICAgaGVpZ2h0OiA5MHB4O1xuICAgIHdpZHRoOiA5MHB4O1xuICAgIGNvbG9yOiB3aGl0ZTtcbiAgICAvLyBiYWNrZ3JvdW5kOiB1cmwoJy4uLy4uLy4uLy4uLy4uL2Fzc2V0cy9pbWcvc3Bpbm5lci5zdmcnKSBuby1yZXBlYXQgNTAlIDUwJTtcbiAgICBiYWNrZ3JvdW5kLXNpemU6IDEwMCU7XG4gICAgXG4gICAgLmstbG9hZGVyLXByaW1hcnkge1xuICAgICAgY29sb3I6ICMwMDc4QTc7XG4gICAgfVxuICB9XG4gIFxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/ui/spinner/components/custom-spinner/custom-spinner.component.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,kBAAA;AADF;AAGE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,qCAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EAEA,qBAAA;AAFJ;AAII;EACE,cAAA;AAFN;AACA,4hCAA4hC","sourcesContent":["@import '../../../../../assets/styles/variables';\n\n.wrapper {\n  display: block;\n  position: relative;\n\n  .spinner {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate3d(-50%, -50%, 0);\n    height: 90px;\n    width: 90px;\n    color: white;\n    // background: url('../../../../../assets/img/spinner.svg') no-repeat 50% 50%;\n    background-size: 100%;\n    \n    .k-loader-primary {\n      color: #0078A7;\n    }\n  }\n  \n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
