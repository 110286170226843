// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li {
  list-style-type: none;
}

.listStyle {
  padding-bottom: 1px;
}

.listChildStyle {
  font-weight: bold !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInVzZXItaGlzdG9yeS1jb250YWluZXIuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDSSxxQkFBQTtBQURKOztBQUlBO0VBQ0UsbUJBQUE7QUFERjs7QUFHQTtFQUNFLDRCQUFBO0FBQUYiLCJmaWxlIjoidXNlci1oaXN0b3J5LWNvbnRhaW5lci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIlxuXG5saSB7XG4gICAgbGlzdC1zdHlsZS10eXBlOiBub25lO1xuICB9XG5cbi5saXN0U3R5bGV7XG4gIHBhZGRpbmctYm90dG9tOiAxcHg7XG59XG4ubGlzdENoaWxkU3R5bGV7XG4gIGZvbnQtd2VpZ2h0OiBib2xkICFpbXBvcnRhbnQ7XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/modules/profile/containers/user-history-container/user-history-container.component.scss"],"names":[],"mappings":"AAEA;EACI,qBAAA;AADJ;;AAIA;EACE,mBAAA;AADF;;AAGA;EACE,4BAAA;AAAF;AACA,ohBAAohB","sourcesContent":["\n\nli {\n    list-style-type: none;\n  }\n\n.listStyle{\n  padding-bottom: 1px;\n}\n.listChildStyle{\n  font-weight: bold !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
