import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import {
  ICreditsRequest,
  ICreditsResponse,
  IUpdateICreditsResponse,
} from "../../../auth/interfaces/user";

enum ACTION_TYPES {
  GET_CREDITS = "[PROFILE CREDITS] Get",
  GET_CREDITS_ERROR = "[PROFILE CREDITS] Get Error",
  GET_CREDITS_SUCCESS = "[PROFILE CREDITS] Get Success",

  UPDATE_CREDITS = "[PROFILE CREDITS] Update",

  SET_SKIP_UPDATE_CREDITS = "[PROFILE] set skip update credits",

  RESET_CREDITS_STATE = "[PROFILE CREDITS] Reset State",
}

export const getCreditsAction: CreateActionType<
  ACTION_TYPES.GET_CREDITS,
  ICreditsRequest
> = createAction(
  ACTION_TYPES.GET_CREDITS,
  createPayload<ICreditsRequest>({ showUpdateMsg: false }),
);
export const getCreditsErrorAction: CreateActionType<
  ACTION_TYPES.GET_CREDITS_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_CREDITS_ERROR, createPayload<IServerError>());
export const getCreditsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_CREDITS_SUCCESS,
  ICreditsResponse
> = createAction(
  ACTION_TYPES.GET_CREDITS_SUCCESS,
  createPayload<ICreditsResponse>(),
);

export const updateCreditsAction: CreateActionType<
  ACTION_TYPES.UPDATE_CREDITS,
  IUpdateICreditsResponse
> = createAction(
  ACTION_TYPES.UPDATE_CREDITS,
  createPayload<IUpdateICreditsResponse>(),
);

export const setSkipUpdateCreditsAction: CreateActionType<
  ACTION_TYPES.SET_SKIP_UPDATE_CREDITS,
  boolean
> = createAction(
  ACTION_TYPES.SET_SKIP_UPDATE_CREDITS,
  createPayload<boolean>(),
);

export const resetCreditsStateAction: ActionCreator<
  ACTION_TYPES.RESET_CREDITS_STATE,
  () => Action<ACTION_TYPES.RESET_CREDITS_STATE>
> = createAction(ACTION_TYPES.RESET_CREDITS_STATE);
