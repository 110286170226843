import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IListCreateData } from "@modules/list-create/interfaces/list-create";
import { IListData } from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";

enum ACTION_TYPES {
  CHANGE_QUERY_RECORDS_TYPE = "[CREATE LIST FROM SELECTED] change query records type",

  CREATE = "[CREATE LIST FROM SELECTED] Create list",
  CREATE_ERROR = "[CREATE LIST FROM SELECTED] Create list error",
  CREATE_SUCCESS = "[CREATE LIST FROM SELECTED] Create list success",

  RESET_CREATE_LIST_STATE = "[CREATE LIST FROM SELECTED] Reset Create List State",
}

export const changeQueryRecordsTypeAction: CreateActionType<
  ACTION_TYPES.CHANGE_QUERY_RECORDS_TYPE,
  number
> = createAction(
  ACTION_TYPES.CHANGE_QUERY_RECORDS_TYPE,
  createPayload<number>(),
);

export const listCreateFromSelectedAction: CreateActionType<
  ACTION_TYPES.CREATE,
  IListCreateData
> = createAction(ACTION_TYPES.CREATE, createPayload<IListCreateData>());
export const createListFromSelectedErrorAction: CreateActionType<
  ACTION_TYPES.CREATE_ERROR,
  IServerError
> = createAction(ACTION_TYPES.CREATE_ERROR, createPayload<IServerError>());
export const createListFromSelectedSuccessAction: CreateActionType<
  ACTION_TYPES.CREATE_SUCCESS,
  IListData
> = createAction(ACTION_TYPES.CREATE_SUCCESS, createPayload<IListData>());

export const resetCreateListBySelectedState: ActionCreator<
  ACTION_TYPES.RESET_CREATE_LIST_STATE,
  () => Action<ACTION_TYPES.RESET_CREATE_LIST_STATE>
> = createAction(ACTION_TYPES.RESET_CREATE_LIST_STATE);
