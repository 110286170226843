import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";

import {
  IGeoCodesSuccess,
  IMultiCodesRequest,
} from "../../../segment/models/codes";

export enum ACTION_TYPES {
  GET = "[GEO CODES] Get",
  GET_ERROR = "[GEO CODES] Get Error",
  GET_SUCCESS = "[GEO CODES] Get Success",

  RESET = "[GEO CODES] Reset State",
}

export const getGeographyCodesAction: CreateActionType<
  ACTION_TYPES.GET,
  Partial<IMultiCodesRequest>
> = createAction(
  ACTION_TYPES.GET,
  createPayload<Partial<IMultiCodesRequest>>(),
);
export const getGeographyCodesErrorAction: CreateActionType<
  ACTION_TYPES.GET_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_ERROR, createPayload<IServerError>());
export const getGeographyCodesSuccessAction: CreateActionType<
  ACTION_TYPES.GET_SUCCESS,
  IGeoCodesSuccess
> = createAction(ACTION_TYPES.GET_SUCCESS, createPayload<IGeoCodesSuccess>());

export const resetGeographyCodesStateAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => Action<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
