// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.menu-content {
  min-width: 169px;
}

.theme--mobile .action {
  text-align: right;
  border-bottom: none;
  padding: 11px 20px;
}
.theme--mobile .action-item:last-child .action {
  border-top: 1px solid #D4D4D4;
  border-bottom: 1px solid #D4D4D4;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImhlbHAtY2VudGVyLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0UsY0FBQTtBQURGOztBQUlBO0VBQ0UsZ0JBQUE7QUFERjs7QUFNSTtFQUNFLGlCQUFBO0VBQ0EsbUJBQUE7RUFDQSxrQkFBQTtBQUhOO0FBTUk7RUFDRSw2QkFBQTtFQUNBLGdDQUFBO0FBSk4iLCJmaWxlIjoiaGVscC1jZW50ZXIuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0ICcuLi8uLi8uLi8uLi8uLi9hc3NldHMvc3R5bGVzL3ZhcmlhYmxlcyc7XG5cbjpob3N0IHtcbiAgZGlzcGxheTogYmxvY2s7XG59XG5cbi5tZW51LWNvbnRlbnQge1xuICBtaW4td2lkdGg6IDE2OXB4O1xufVxuXG4udGhlbWUge1xuICAmLS1tb2JpbGUge1xuICAgIC5hY3Rpb24ge1xuICAgICAgdGV4dC1hbGlnbjogcmlnaHQ7XG4gICAgICBib3JkZXItYm90dG9tOiBub25lO1xuICAgICAgcGFkZGluZzogMTFweCAyMHB4O1xuICAgIH1cblxuICAgIC5hY3Rpb24taXRlbTpsYXN0LWNoaWxkIC5hY3Rpb24ge1xuICAgICAgYm9yZGVyLXRvcDogMXB4IHNvbGlkICRncmF5LWxpZ2h0O1xuICAgICAgYm9yZGVyLWJvdHRvbTogMXB4IHNvbGlkICRncmF5LWxpZ2h0O1xuICAgIH1cbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/modules/nav/components/help-center/help-center.component.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;AADF;;AAIA;EACE,gBAAA;AADF;;AAMI;EACE,iBAAA;EACA,mBAAA;EACA,kBAAA;AAHN;AAMI;EACE,6BAAA;EACA,gCAAA;AAJN;AACA,45BAA45B","sourcesContent":["@import '../../../../../assets/styles/variables';\n\n:host {\n  display: block;\n}\n\n.menu-content {\n  min-width: 169px;\n}\n\n.theme {\n  &--mobile {\n    .action {\n      text-align: right;\n      border-bottom: none;\n      padding: 11px 20px;\n    }\n\n    .action-item:last-child .action {\n      border-top: 1px solid $gray-light;\n      border-bottom: 1px solid $gray-light;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
