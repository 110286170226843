import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IGetPersonnelEntity,
  IPersonnelEntityResponse,
  ISearchPersonnelEntity,
  ISelectedPersons,
  ISelectPersonnel,
  ISortPersonnelEntity,
  IUpdateTableEmailByPersonId,
} from "@shared/modules/personnel-entity/interfaces/personnel-entity";

enum ACTION_TYPES {
  GET_PERSONNEL_ENTITY = "[PERSONNEL ENTITY] get personnel entity",
  GET_PERSONNEL_ENTITY_ERROR = "[PERSONNEL ENTITY] get personnel entity error",
  GET_PERSONNEL_ENTITY_SUCCESS = "[PERSONNEL ENTITY] get personnel entity success",

  SORT_PERSONNEL_ENTITY = "[PERSONNEL ENTITY] sort personnel entity",
  SORT_PERSONNEL_ENTITY_ERROR = "[PERSONNEL ENTITY] sort personnel entity error",
  SORT_PERSONNEL_ENTITY_SUCCESS = "[PERSONNEL ENTITY] sort personnel entity success",

  SEARCH_PERSONNEL_ENTITY = "[PERSONNEL ENTITY] search personnel entity",

  SET_CHECKED_PERSONNEL_ENTITY = "[PERSONNEL ENTITY] set checked personnel entity",
  SET_ALL_CHECKED_PERSONNEL_ENTITY = "[PERSONNEL ENTITY] set all checked personnel entity",
  RESET_CHECKED_PERSONNEL_ENTITY = "[PERSONNEL ENTITY] reset checked personnel entity",

  CREATE_LIST_FROM_SELECTED = "[PERSONNEL ENTITY] create list from selected persons",

  UPDATE_TABLE_EMAIL_BY_PERSON_ID = "[PERSONNEL ENTITY] update table email by person id",

  RESET_PERSONNEL_ENTITY_STATE = "[PERSONNEL ENTITY] reset personnel entity state",
}

export const getPersonnelEntityAction: CreateActionType<
  ACTION_TYPES.GET_PERSONNEL_ENTITY,
  IGetPersonnelEntity
> = createAction(
  ACTION_TYPES.GET_PERSONNEL_ENTITY,
  createPayload<IGetPersonnelEntity>(),
);
export const getPersonnelEntityErrorAction: CreateActionType<
  ACTION_TYPES.GET_PERSONNEL_ENTITY_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_PERSONNEL_ENTITY_ERROR,
  createPayload<IServerError>(),
);
export const getPersonnelEntitySuccessAction: CreateActionType<
  ACTION_TYPES.GET_PERSONNEL_ENTITY_SUCCESS,
  IPersonnelEntityResponse
> = createAction(
  ACTION_TYPES.GET_PERSONNEL_ENTITY_SUCCESS,
  createPayload<IPersonnelEntityResponse>(),
);

export const sortPersonnelEntityAction: CreateActionType<
  ACTION_TYPES.SORT_PERSONNEL_ENTITY,
  ISortPersonnelEntity
> = createAction(
  ACTION_TYPES.SORT_PERSONNEL_ENTITY,
  createPayload<ISortPersonnelEntity>(),
);
export const sortPersonnelEntityErrorAction: CreateActionType<
  ACTION_TYPES.SORT_PERSONNEL_ENTITY_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.SORT_PERSONNEL_ENTITY_ERROR,
  createPayload<IServerError>(),
);
export const sortPersonnelEntitySuccessAction: CreateActionType<
  ACTION_TYPES.SORT_PERSONNEL_ENTITY_SUCCESS,
  IPersonnelEntityResponse
> = createAction(
  ACTION_TYPES.SORT_PERSONNEL_ENTITY_SUCCESS,
  createPayload<IPersonnelEntityResponse>(),
);

export const searchPersonnelEntityAction: CreateActionType<
  ACTION_TYPES.SEARCH_PERSONNEL_ENTITY,
  ISearchPersonnelEntity
> = createAction(
  ACTION_TYPES.SEARCH_PERSONNEL_ENTITY,
  createPayload<ISearchPersonnelEntity>(),
);

export const setCheckedPersonnelEntityActions: CreateActionType<
  ACTION_TYPES.SET_CHECKED_PERSONNEL_ENTITY,
  ISelectPersonnel
> = createAction(
  ACTION_TYPES.SET_CHECKED_PERSONNEL_ENTITY,
  createPayload<ISelectPersonnel>(),
);
export const checkAllPersonnelEntityAction: CreateActionType<
  ACTION_TYPES.SET_ALL_CHECKED_PERSONNEL_ENTITY,
  boolean
> = createAction(
  ACTION_TYPES.SET_ALL_CHECKED_PERSONNEL_ENTITY,
  createPayload<boolean>(),
);
export const resetCheckedPersonnelEntityAction: ActionCreator<
  ACTION_TYPES.RESET_CHECKED_PERSONNEL_ENTITY,
  () => Action<ACTION_TYPES.RESET_CHECKED_PERSONNEL_ENTITY>
> = createAction(ACTION_TYPES.RESET_CHECKED_PERSONNEL_ENTITY);

export const createListFromSelectedAction: CreateActionType<
  ACTION_TYPES.CREATE_LIST_FROM_SELECTED,
  ISelectedPersons
> = createAction(
  ACTION_TYPES.CREATE_LIST_FROM_SELECTED,
  createPayload<ISelectedPersons>(),
);

export const updateTableEmailByPersonIdAction: CreateActionType<
  ACTION_TYPES.UPDATE_TABLE_EMAIL_BY_PERSON_ID,
  IUpdateTableEmailByPersonId
> = createAction(
  ACTION_TYPES.UPDATE_TABLE_EMAIL_BY_PERSON_ID,
  createPayload<IUpdateTableEmailByPersonId>(),
);

export const resetPersonnelEntityAction: ActionCreator<
  ACTION_TYPES.RESET_PERSONNEL_ENTITY_STATE,
  () => Action<ACTION_TYPES.RESET_PERSONNEL_ENTITY_STATE>
> = createAction(ACTION_TYPES.RESET_PERSONNEL_ENTITY_STATE);
