import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IDataListSummary, IListData } from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";
import { ICDLListChangeStatusPayload } from "../../../../dashboard-lists/interfaces";
import { ISegmentData } from "../../../../segment/interfaces/segment";

enum ACTION_TYPES {
  FETCH_SUMMARY_DATA = "[CDL LIST PURCHASE]: Fetch cdl list summary data",
  FETCH_SUMMARY_DATA_ERROR = "[CDL LIST PURCHASE]: Fetch cdl list summary data error",
  FETCH_SUMMARY_DATA_SUCCESS = "[CDL LIST PURCHASE]: Fetch cdl list summary data success",

  FETCH_LIST_SEGMENT_DATA = "[CDL LIST PURCHASE]: fetch cdl list purchase segment data",
  FETCH_LIST_SEGMENT_DATA_ERROR = "[CDL LIST PURCHASE]: fetch cdl list purchase segment data error",
  FETCH_LIST_SEGMENT_DATA_SUCCESS = "[CDL LIST PURCHASE]: fetch cdl list purchase segment data success",

  SHOW_CDL_LIST_CHANGE_STATUS_POP_UP = "[CDL LIST PURCHASE]: show cdl list change status pop up",

  CDL_LIST_CHANGE_STATUS = "[CDL LIST PURCHASE]: cdl list change status",
  CDL_LIST_CHANGE_STATUS_ERROR = "[CDL LIST PURCHASE]: cdl list change status error",
  CDL_LIST_CHANGE_STATUS_SUCCESS = "[CDL LIST PURCHASE]: cdl list change status success",

  DUPLICATE_CDL_LIST = "[CDL LIST PURCHASE]: duplicate cdl list",
  DUPLICATE_CDL_LIST_ERROR = "[CDL LIST PURCHASE]: duplicate cdl list error",
  DUPLICATE_CDL_LIST_SUCCESS = "[CDL LIST PURCHASE]: duplicate cdl list success",

  SHOW_DELETE_CDL_LIST_POP_UP = "[CDL LIST PURCHASE]: show delete cdl list pop up",

  DELETE_CDL_LIST = "[CDL LIST PURCHASE]: delete cdl list",
  DELETE_CDL_LIST_ERROR = "[CDL LIST PURCHASE]: delete cdl list error",
  DELETE_CDL_LIST_SUCCESS = "[CDL LIST PURCHASE]: delete cdl list success",

  RENAME_CDL_LIST = "[CDL LIST PURCHASE]: rename cdl list",
  RENAME_CDL_LIST_ERROR = "[CDL LIST PURCHASE]: rename cdl list error",
  RENAME_CDL_LIST_SUCCESS = "[CDL LIST PURCHASE]: rename cdl list success",
  RENAME_CDL_LIST_CANCEL = "[CDL LIST PURCHASE]: rename cdl list cancel",

  RESET = "[CDL LIST PURCHASE]: Reset state",
}

export const fetchCDLListPurchaseDataAction: CreateActionType<
  ACTION_TYPES.FETCH_SUMMARY_DATA,
  number
> = createAction(ACTION_TYPES.FETCH_SUMMARY_DATA, createPayload<number>());
export const fetchCDLListPurchaseDataErrorAction: CreateActionType<
  ACTION_TYPES.FETCH_SUMMARY_DATA_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.FETCH_SUMMARY_DATA_ERROR,
  createPayload<IServerError>(),
);
export const fetchCDLListPurchaseDataSuccessAction: CreateActionType<
  ACTION_TYPES.FETCH_SUMMARY_DATA_SUCCESS,
  IDataListSummary
> = createAction(
  ACTION_TYPES.FETCH_SUMMARY_DATA_SUCCESS,
  createPayload<IDataListSummary>(),
);

export const fetchCDLListSegmentDataAction: CreateActionType<
  ACTION_TYPES.FETCH_LIST_SEGMENT_DATA,
  number
> = createAction(ACTION_TYPES.FETCH_LIST_SEGMENT_DATA, createPayload<number>());
export const fetchCDLListSegmentDataErrorAction: CreateActionType<
  ACTION_TYPES.FETCH_LIST_SEGMENT_DATA_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.FETCH_LIST_SEGMENT_DATA_ERROR,
  createPayload<IServerError>(),
);
export const fetchCDLListSegmentDataSuccessAction: CreateActionType<
  ACTION_TYPES.FETCH_LIST_SEGMENT_DATA_SUCCESS,
  ISegmentData
> = createAction(
  ACTION_TYPES.FETCH_LIST_SEGMENT_DATA_SUCCESS,
  createPayload<ISegmentData>(),
);

export const showCDLListChangeStatusPopUpAction: CreateActionType<
  ACTION_TYPES.SHOW_CDL_LIST_CHANGE_STATUS_POP_UP,
  ICDLListChangeStatusPayload
> = createAction(
  ACTION_TYPES.SHOW_CDL_LIST_CHANGE_STATUS_POP_UP,
  createPayload<ICDLListChangeStatusPayload>(),
);

export const changeCDLListStatusAction: CreateActionType<
  ACTION_TYPES.CDL_LIST_CHANGE_STATUS,
  number
> = createAction(ACTION_TYPES.CDL_LIST_CHANGE_STATUS, createPayload<number>());
export const changeCDLListStatusErrorAction: CreateActionType<
  ACTION_TYPES.CDL_LIST_CHANGE_STATUS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.CDL_LIST_CHANGE_STATUS_ERROR,
  createPayload<IServerError>(),
);
export const changeCDLListStatusSuccessAction: CreateActionType<
  ACTION_TYPES.CDL_LIST_CHANGE_STATUS_SUCCESS,
  number
> = createAction(
  ACTION_TYPES.CDL_LIST_CHANGE_STATUS_SUCCESS,
  createPayload<number>(),
);

export const duplicateCDLListAction: CreateActionType<
  ACTION_TYPES.DUPLICATE_CDL_LIST,
  number
> = createAction(ACTION_TYPES.DUPLICATE_CDL_LIST, createPayload<number>());
export const duplicateCDLListErrorAction: CreateActionType<
  ACTION_TYPES.DUPLICATE_CDL_LIST_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.DUPLICATE_CDL_LIST_ERROR,
  createPayload<IServerError>(),
);
export const duplicateCDLListSuccessAction: ActionCreator<
  ACTION_TYPES.DUPLICATE_CDL_LIST_SUCCESS,
  () => Action<ACTION_TYPES.DUPLICATE_CDL_LIST_SUCCESS>
> = createAction(ACTION_TYPES.DUPLICATE_CDL_LIST_SUCCESS);

export const showDeleteCDLListPopUpAction: CreateActionType<
  ACTION_TYPES.SHOW_DELETE_CDL_LIST_POP_UP,
  number
> = createAction(
  ACTION_TYPES.SHOW_DELETE_CDL_LIST_POP_UP,
  createPayload<number>(),
);

export const deleteCDLListAction: CreateActionType<
  ACTION_TYPES.DELETE_CDL_LIST,
  number
> = createAction(ACTION_TYPES.DELETE_CDL_LIST, createPayload<number>());
export const deleteCDLListErrorAction: CreateActionType<
  ACTION_TYPES.DELETE_CDL_LIST_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.DELETE_CDL_LIST_ERROR,
  createPayload<IServerError>(),
);
export const deleteCDLListSuccessAction: ActionCreator<
  ACTION_TYPES.DELETE_CDL_LIST_SUCCESS,
  () => Action<ACTION_TYPES.DELETE_CDL_LIST_SUCCESS>
> = createAction(ACTION_TYPES.DELETE_CDL_LIST_SUCCESS);

export const renameCDLListPurchaseAction: CreateActionType<
  ACTION_TYPES.RENAME_CDL_LIST,
  string
> = createAction(ACTION_TYPES.RENAME_CDL_LIST, createPayload<string>()); // list name
export const renameCDLListPurchaseErrorAction: CreateActionType<
  ACTION_TYPES.RENAME_CDL_LIST_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.RENAME_CDL_LIST_ERROR,
  createPayload<IServerError>(),
);
export const renameCDLListPurchaseSuccessAction: CreateActionType<
  ACTION_TYPES.RENAME_CDL_LIST_SUCCESS,
  IListData
> = createAction(
  ACTION_TYPES.RENAME_CDL_LIST_SUCCESS,
  createPayload<IListData>(),
);
export const renameCDLListPurchaseCancelAction: ActionCreator<
  ACTION_TYPES.RENAME_CDL_LIST_CANCEL,
  () => Action<ACTION_TYPES.RENAME_CDL_LIST_CANCEL>
> = createAction(ACTION_TYPES.RENAME_CDL_LIST_CANCEL);

export const resetCDLListStateAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => Action<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
