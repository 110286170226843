import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { IFilters, IFiltersResult } from "../../../dashboard/interfaces";

import { MANAGE_ACCOUNT_LISTS } from "@shared/constants/data/manage-account-lists";

enum ACTION_TYPES {
  GET_FILTER = "[DASHBOARD LISTS]: Get filters",
  GET_FILTER_ERROR = "[DASHBOARD LISTS]: Get filters error",
  GET_FILTER_SUCCESS = "[DASHBOARD LISTS]: Get filters success",

  UPDATE_CHECKED_FILTER = "[DASHBOARD LISTS]: Update filter",
  UPDATE_CHECKED_FILTER_SUCCESS = "[DASHBOARD LISTS]: Update filter success",
  RESET_CHECKED_FILTERS = "[DASHBOARD LISTS]: Reset checked filters",

  SWITCH_FILTERS_WITH_RELOAD = "[DASHBOARD LISTS]: Switch filters with reload for lists",
  RESET_SWITCH_FILTERS = "[DASHBOARD LISTS]: Reset switch filters for lists",

  RESET_FILTERS = "[DASHBOARD LISTS]: Reset filters",

  RESET_STATE = "[DASHBOARD LISTS]: Reset state",
}

export const getDashboardListsFilterAction: CreateActionType<
  ACTION_TYPES.GET_FILTER,
  MANAGE_ACCOUNT_LISTS
> = createAction(
  ACTION_TYPES.GET_FILTER,
  createPayload<MANAGE_ACCOUNT_LISTS>(),
);
export const getDashboardListsFilterErrorAction: CreateActionType<
  ACTION_TYPES.GET_FILTER_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_FILTER_ERROR, createPayload<IServerError>());
export const getDashboardListsFilterSuccessAction: CreateActionType<
  ACTION_TYPES.GET_FILTER_SUCCESS,
  IFilters
> = createAction(ACTION_TYPES.GET_FILTER_SUCCESS, createPayload<IFilters>());

export const updateDashboardListsFilterAction: CreateActionType<
  ACTION_TYPES.UPDATE_CHECKED_FILTER,
  IFiltersResult
> = createAction(
  ACTION_TYPES.UPDATE_CHECKED_FILTER,
  createPayload<IFiltersResult>(),
);
export const updateDashboardListsFilterSuccessAction: ActionCreator<
  ACTION_TYPES.UPDATE_CHECKED_FILTER_SUCCESS,
  () => Action<ACTION_TYPES.UPDATE_CHECKED_FILTER_SUCCESS>
> = createAction(ACTION_TYPES.UPDATE_CHECKED_FILTER_SUCCESS);
export const resetDashboardListsCheckedFilterAction: ActionCreator<
  ACTION_TYPES.RESET_CHECKED_FILTERS,
  () => Action<ACTION_TYPES.RESET_CHECKED_FILTERS>
> = createAction(ACTION_TYPES.RESET_CHECKED_FILTERS);

export const dashboardListsSwitchFiltersWithReloadAction: CreateActionType<
  ACTION_TYPES.SWITCH_FILTERS_WITH_RELOAD,
  MANAGE_ACCOUNT_LISTS
> = createAction(
  ACTION_TYPES.SWITCH_FILTERS_WITH_RELOAD,
  createPayload<MANAGE_ACCOUNT_LISTS>(),
);
export const resetSwitchFiltersAction: ActionCreator<
  ACTION_TYPES.RESET_SWITCH_FILTERS,
  () => Action<ACTION_TYPES.RESET_SWITCH_FILTERS>
> = createAction(ACTION_TYPES.RESET_SWITCH_FILTERS);
export const resetDashboardListsFilterAction: ActionCreator<
  ACTION_TYPES.RESET_FILTERS,
  () => Action<ACTION_TYPES.RESET_FILTERS>
> = createAction(ACTION_TYPES.RESET_FILTERS);

export const resetDashboardListsStateAction: ActionCreator<
  ACTION_TYPES.RESET_STATE,
  () => Action<ACTION_TYPES.RESET_STATE>
> = createAction(ACTION_TYPES.RESET_STATE);
