import { Params } from "@angular/router";
import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { IDistrictSchoolsResponse } from "@shared/modules/institution-profiles/interfaces/district-school";

enum ACTION_TYPES {
  GET_DISTRICT_SCHOOLS = "[DISTRICT SCHOOLS] get district schools",
  GET_DISTRICT_SCHOOLS_ERROR = "[DISTRICT SCHOOLS] get district schools Error",
  GET_DISTRICT_SCHOOLS_SUCCESS = "[DISTRICT SCHOOLS] get district schools Success",

  SORT_DISTRICT_SCHOOLS = "[DISTRICT SCHOOLS] sort district schools",
  SORT_DISTRICT_SCHOOLS_ERROR = "[DISTRICT SCHOOLS] sort district schools error",
  SORT_DISTRICT_SCHOOLS_SUCCESS = "[DISTRICT SCHOOLS] sort district schools success",

  TOGGLE_DISTRICT_SCHOOLS = "[DISTRICT SCHOOLS] toggle district schools",
  TOGGLE_DISTRICT_SCHOOLS_SUCCESS = "[DISTRICT SCHOOLS] toggle district schools success",

  SET_DISTRICT_CHECKED_SCHOOL = "[DISTRICT SCHOOL] set district checked schools",
  SET_ALL_DISTRICT_SCHOOLS_CHECKED = "[DISTRICT SCHOOLS] set all district checked schools",
  RESET_CHECKED_DISTRICT_SCHOOLS = "[DISTRICT SCHOOLS] reset checked schools",

  RESET_DISTRICT_SCHOOLS_STATE = "[DISTRICT SCHOOLS] reset district schools state",
}

export const getDistrictSchoolsAction: CreateActionType<
  ACTION_TYPES.GET_DISTRICT_SCHOOLS,
  number
> = createAction(ACTION_TYPES.GET_DISTRICT_SCHOOLS, createPayload<number>());
export const getDistrictSchoolsErrorAction: CreateActionType<
  ACTION_TYPES.GET_DISTRICT_SCHOOLS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_DISTRICT_SCHOOLS_ERROR,
  createPayload<IServerError>(),
);
export const getDistrictSchoolsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_DISTRICT_SCHOOLS_SUCCESS,
  IDistrictSchoolsResponse
> = createAction(
  ACTION_TYPES.GET_DISTRICT_SCHOOLS_SUCCESS,
  createPayload<IDistrictSchoolsResponse>(),
);

export const sortDistrictSchoolsAction: CreateActionType<
  ACTION_TYPES.SORT_DISTRICT_SCHOOLS,
  Params
> = createAction(ACTION_TYPES.SORT_DISTRICT_SCHOOLS, createPayload<Params>());
export const sortDistrictSchoolsErrorAction: CreateActionType<
  ACTION_TYPES.SORT_DISTRICT_SCHOOLS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.SORT_DISTRICT_SCHOOLS_ERROR,
  createPayload<IServerError>(),
);
export const sortDistrictSchoolsSuccessAction: CreateActionType<
  ACTION_TYPES.SORT_DISTRICT_SCHOOLS_SUCCESS,
  IDistrictSchoolsResponse
> = createAction(
  ACTION_TYPES.SORT_DISTRICT_SCHOOLS_SUCCESS,
  createPayload<IDistrictSchoolsResponse>(),
);

export const toggleDistrictSchoolsAction: ActionCreator<
  ACTION_TYPES.TOGGLE_DISTRICT_SCHOOLS,
  () => Action<ACTION_TYPES.TOGGLE_DISTRICT_SCHOOLS>
> = createAction(ACTION_TYPES.TOGGLE_DISTRICT_SCHOOLS);
export const toggleDistrictSchoolsSuccessAction: ActionCreator<
  ACTION_TYPES.TOGGLE_DISTRICT_SCHOOLS_SUCCESS,
  () => Action<ACTION_TYPES.TOGGLE_DISTRICT_SCHOOLS_SUCCESS>
> = createAction(ACTION_TYPES.TOGGLE_DISTRICT_SCHOOLS_SUCCESS);

export const resetDistrictCheckedSchoolsAction: ActionCreator<
  ACTION_TYPES.RESET_CHECKED_DISTRICT_SCHOOLS,
  () => Action<ACTION_TYPES.RESET_CHECKED_DISTRICT_SCHOOLS>
> = createAction(ACTION_TYPES.RESET_CHECKED_DISTRICT_SCHOOLS);

export const setDistrictCheckedSchoolAction: CreateActionType<
  ACTION_TYPES.SET_DISTRICT_CHECKED_SCHOOL,
  number
> = createAction(
  ACTION_TYPES.SET_DISTRICT_CHECKED_SCHOOL,
  createPayload<number>(),
);

export const checkAllDistrictSchoolsAction: CreateActionType<
  ACTION_TYPES.SET_ALL_DISTRICT_SCHOOLS_CHECKED,
  boolean
> = createAction(
  ACTION_TYPES.SET_ALL_DISTRICT_SCHOOLS_CHECKED,
  createPayload<boolean>(),
);

export const resetDistrictSchoolsAction: ActionCreator<
  ACTION_TYPES.RESET_DISTRICT_SCHOOLS_STATE,
  () => Action<ACTION_TYPES.RESET_DISTRICT_SCHOOLS_STATE>
> = createAction(ACTION_TYPES.RESET_DISTRICT_SCHOOLS_STATE);
