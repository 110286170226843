import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IProductsResponse } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";
import { IViewData } from "@shared/interfaces/view";
import { IChangePagePayload } from "../../../dashboard-lists/interfaces";
import { IProductNavigationPayload } from "../../../dashboard/interfaces";
import { IRenameView } from "../../interfaces";

enum ACTION_TYPES {
  GET_VIEWS = "[DASHBOARD VIEWS]: get views",
  GET_VIEWS_ERROR = "[DASHBOARD VIEWS]: get views error",
  GET_VIEWS_SUCCESS = "[DASHBOARD VIEWS]: get views success",

  TO_NEXT_VIEWS_PAGE = "[DASHBOARD VIEWS]: next page",
  TO_NEXT_VIEWS_PAGE_SUCCESS = "[DASHBOARD VIEWS]: next page success",

  CHANGE_PAGE_WITH_OR_WITHOUT_LOAD_DATA = "[DASHBOARD VIEWS]: change page with or without load data",
  CHANGE_PAGE_WITHOUT_LOAD_DATA = "[DASHBOARD VIEWS]: change page without load data",
  CHANGE_PAGE = "[DASHBOARD VIEWS]: change current page",
  CHANGE_PAGE_SUCCESS = "[DASHBOARD VIEWS]: change current page success",

  RELOAD_VIEWS = "[DASHBOARD VIEWS]: reload views",
  RELOAD_VIEWS_ERROR = "[DASHBOARD VIEWS]: reload views error",
  RELOAD_VIEWS_SUCCESS = "[DASHBOARD VIEWS]: reload views success",

  VIEW_NAVIGATE = "[DASHBOARD VIEWS]: view navigate",
  VIEW_NAVIGATE_CANCEL = "[DASHBOARD VIEWS]: view navigate cancel",

  RENAME_VIEW = "[DASHBOARD VIEWS]: rename view",
  RENAME_VIEW_ERROR = "[DASHBOARD VIEWS]: rename view error",
  RENAME_VIEW_SUCCESS = "[DASHBOARD VIEWS]: rename view success",

  RENAME_VIEW_CANCEL = "[DASHBOARD VIEWS]: rename view cancel",

  DUPLICATE_VIEW = "[DASHBOARD VIEWS]: duplicate view",
  DUPLICATE_VIEW_ERROR = "[DASHBOARD VIEWS]: duplicate view error",
  DUPLICATE_VIEW_SUCCESS = "[DASHBOARD VIEWS]: duplicate view success",

  SHOW_DELETE_VIEW_POP_UP = "[DASHBOARD VIEWS]: show delete view pop up",
  DELETE_VIEW = "[DASHBOARD VIEWS]: delete view",
  DELETE_VIEW_ERROR = "[DASHBOARD VIEWS]: delete view error",
  DELETE_VIEW_SUCCESS = "[DASHBOARD VIEWS]: delete view success",

  SHOW_RENEWAL_POP_UP = "[DASHBOARD VIEWS]: show renewal pop up",
  SHOW_STATE_SEATS_ASSIGN_POP_UP = "[DASHBOARD VIEWS]: show state seats assign pop up",

  RESET_VIEWS = "[DASHBOARD VIEWS]: dashboard reset views",
}

export const getDashboardViewsAction: ActionCreator<
  ACTION_TYPES.GET_VIEWS,
  () => Action<ACTION_TYPES.GET_VIEWS>
> = createAction(ACTION_TYPES.GET_VIEWS);
export const getDashboardViewsErrorAction: CreateActionType<
  ACTION_TYPES.GET_VIEWS_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_VIEWS_ERROR, createPayload<IServerError>());
export const getDashboardViewsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_VIEWS_SUCCESS,
  IProductsResponse
> = createAction(
  ACTION_TYPES.GET_VIEWS_SUCCESS,
  createPayload<IProductsResponse>(),
);

export const getDashboardViewsNextPageAction: CreateActionType<
  ACTION_TYPES.TO_NEXT_VIEWS_PAGE,
  { limit: number }
> = createAction(
  ACTION_TYPES.TO_NEXT_VIEWS_PAGE,
  createPayload<{ limit: number }>(),
);
export const getDashboardViewsNextPageSuccessAction: CreateActionType<
  ACTION_TYPES.TO_NEXT_VIEWS_PAGE_SUCCESS,
  IProductsResponse
> = createAction(
  ACTION_TYPES.TO_NEXT_VIEWS_PAGE_SUCCESS,
  createPayload<IProductsResponse>(),
);

export const changeViewPageWithOrWithoutLoadDataAction: CreateActionType<
  ACTION_TYPES.CHANGE_PAGE_WITH_OR_WITHOUT_LOAD_DATA,
  IChangePagePayload
> = createAction(
  ACTION_TYPES.CHANGE_PAGE_WITH_OR_WITHOUT_LOAD_DATA,
  createPayload<IChangePagePayload>(),
);
export const changeViewPageWithoutLoadDataAction: CreateActionType<
  ACTION_TYPES.CHANGE_PAGE_WITHOUT_LOAD_DATA,
  number
> = createAction(
  ACTION_TYPES.CHANGE_PAGE_WITHOUT_LOAD_DATA,
  createPayload<number>(),
); // currentPage
export const changeViewPageAction: CreateActionType<
  ACTION_TYPES.CHANGE_PAGE,
  number
> = createAction(ACTION_TYPES.CHANGE_PAGE, createPayload<number>()); // currentPage
export const changeViewPageSuccessAction: CreateActionType<
  ACTION_TYPES.CHANGE_PAGE_SUCCESS,
  IProductsResponse
> = createAction(
  ACTION_TYPES.CHANGE_PAGE_SUCCESS,
  createPayload<IProductsResponse>(),
);

export const reloadDashboardViewsAction: ActionCreator<
  ACTION_TYPES.RELOAD_VIEWS,
  () => Action<ACTION_TYPES.RELOAD_VIEWS>
> = createAction(ACTION_TYPES.RELOAD_VIEWS);
export const reloadDashboardViewsErrorAction: CreateActionType<
  ACTION_TYPES.RELOAD_VIEWS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.RELOAD_VIEWS_ERROR,
  createPayload<IServerError>(),
);
export const reloadDashboardViewsSuccessAction: CreateActionType<
  ACTION_TYPES.RELOAD_VIEWS_SUCCESS,
  IProductsResponse
> = createAction(
  ACTION_TYPES.RELOAD_VIEWS_SUCCESS,
  createPayload<IProductsResponse>(),
);

export const viewNavigateAction: CreateActionType<
  ACTION_TYPES.VIEW_NAVIGATE,
  IProductNavigationPayload
> = createAction(
  ACTION_TYPES.VIEW_NAVIGATE,
  createPayload<IProductNavigationPayload>(),
);
export const viewNavigateCancelAction: ActionCreator<
  ACTION_TYPES.VIEW_NAVIGATE_CANCEL,
  () => Action<ACTION_TYPES.VIEW_NAVIGATE_CANCEL>
> = createAction(ACTION_TYPES.VIEW_NAVIGATE_CANCEL);

export const renameDashboardViewAction: CreateActionType<
  ACTION_TYPES.RENAME_VIEW,
  IRenameView
> = createAction(ACTION_TYPES.RENAME_VIEW, createPayload<IRenameView>());
export const renameDashboardViewErrorAction: CreateActionType<
  ACTION_TYPES.RENAME_VIEW_ERROR,
  IServerError
> = createAction(ACTION_TYPES.RENAME_VIEW_ERROR, createPayload<IServerError>());
export const renameDashboardViewSuccessAction: CreateActionType<
  ACTION_TYPES.RENAME_VIEW_SUCCESS,
  IViewData
> = createAction(ACTION_TYPES.RENAME_VIEW_SUCCESS, createPayload<IViewData>());
export const renameDashboardViewCancelAction: ActionCreator<
  ACTION_TYPES.RENAME_VIEW_CANCEL,
  () => Action<ACTION_TYPES.RENAME_VIEW_CANCEL>
> = createAction(ACTION_TYPES.RENAME_VIEW_CANCEL);

export const duplicateDashboardViewAction: CreateActionType<
  ACTION_TYPES.DUPLICATE_VIEW,
  { viewId: number }
> = createAction(
  ACTION_TYPES.DUPLICATE_VIEW,
  createPayload<{ viewId: number }>(),
);
export const duplicateDashboardViewErrorAction: CreateActionType<
  ACTION_TYPES.DUPLICATE_VIEW_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.DUPLICATE_VIEW_ERROR,
  createPayload<IServerError>(),
);
export const duplicateDashboardSuccessViewAction: CreateActionType<
  ACTION_TYPES.DUPLICATE_VIEW_SUCCESS,
  IViewData
> = createAction(
  ACTION_TYPES.DUPLICATE_VIEW_SUCCESS,
  createPayload<IViewData>(),
);

export const showDeleteViewPopUpAction: CreateActionType<
  ACTION_TYPES.SHOW_DELETE_VIEW_POP_UP,
  number
> = createAction(ACTION_TYPES.SHOW_DELETE_VIEW_POP_UP, createPayload<number>()); // viewId
export const deleteDashboardViewAction: CreateActionType<
  ACTION_TYPES.DELETE_VIEW,
  { viewId: number }
> = createAction(ACTION_TYPES.DELETE_VIEW, createPayload<{ viewId: number }>());
export const deleteDashboardViewErrorAction: CreateActionType<
  ACTION_TYPES.DELETE_VIEW_ERROR,
  IServerError
> = createAction(ACTION_TYPES.DELETE_VIEW_ERROR, createPayload<IServerError>());
export const deleteDashboardViewSuccessAction: CreateActionType<
  ACTION_TYPES.DELETE_VIEW_SUCCESS,
  any
> = createAction(ACTION_TYPES.DELETE_VIEW_SUCCESS, createPayload<any>());

export const showRenewalPopUpAction: CreateActionType<
  ACTION_TYPES.SHOW_RENEWAL_POP_UP,
  string
> = createAction(ACTION_TYPES.SHOW_RENEWAL_POP_UP, createPayload<string>());
export const showStateSeatsAssignPopUpAction: ActionCreator<
  ACTION_TYPES.SHOW_STATE_SEATS_ASSIGN_POP_UP,
  () => Action<ACTION_TYPES.SHOW_STATE_SEATS_ASSIGN_POP_UP>
> = createAction(ACTION_TYPES.SHOW_STATE_SEATS_ASSIGN_POP_UP);

export const resetDashboardViewsAction: ActionCreator<
  ACTION_TYPES.RESET_VIEWS,
  () => Action<ACTION_TYPES.RESET_VIEWS>
> = createAction(ACTION_TYPES.RESET_VIEWS);
