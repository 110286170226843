import { Params } from "@angular/router";
import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IGrantsRecord } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";
import { IGetViewResultsInstitutionSuccessPayload } from "../../interfaces";

enum ACTION_TYPES {
  GET_VIEW_RESULTS_GRANTS = "[VIEW RESULTS GRANTS] Get grants",
  GET_VIEW_RESULTS_GRANTS_ERROR = "[VIEW RESULTS GRANTS] Get grants error",
  GET_VIEW_RESULTS_GRANTS_SUCCESS = "[VIEW RESULTS GRANTS] Get grants success",

  SET_VIEW_RESULTS_GRANTS_SPINNER_STATE = "[VIEW RESULTS GRANTS] Set spinner state",

  CHANGE_VIEW_RESULTS_GRANTS_PAGE = "[VIEW RESULTS GRANTS] Change page",
  CHANGE_VIEW_RESULTS_GRANTS_QUERY = "[[VIEW RESULTS GRANTS] Change query",

  RESET_VIEW_RESULTS_GRANTS_STATE = "[VIEW RESULTS GRANTS] Reset state",
}

export const getViewResultsGrantsAction: ActionCreator<
  ACTION_TYPES.GET_VIEW_RESULTS_GRANTS,
  () => Action<ACTION_TYPES.GET_VIEW_RESULTS_GRANTS>
> = createAction(ACTION_TYPES.GET_VIEW_RESULTS_GRANTS);
export const getViewResultsGrantsErrorAction: CreateActionType<
  ACTION_TYPES.GET_VIEW_RESULTS_GRANTS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_VIEW_RESULTS_GRANTS_ERROR,
  createPayload<IServerError>(),
);

export const getViewResultsGrantsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_VIEW_RESULTS_GRANTS_SUCCESS,
  IGetViewResultsInstitutionSuccessPayload<IGrantsRecord>
> = createAction(
  ACTION_TYPES.GET_VIEW_RESULTS_GRANTS_SUCCESS,
  createPayload<IGetViewResultsInstitutionSuccessPayload<IGrantsRecord>>(),
);

export const setViewResultsGrantsSpinnerStateAction: CreateActionType<
  ACTION_TYPES.SET_VIEW_RESULTS_GRANTS_SPINNER_STATE,
  boolean
> = createAction(
  ACTION_TYPES.SET_VIEW_RESULTS_GRANTS_SPINNER_STATE,
  createPayload<boolean>(),
);

export const changeViewResultsGrantsPageAction: CreateActionType<
  ACTION_TYPES.CHANGE_VIEW_RESULTS_GRANTS_PAGE,
  number
> = createAction(
  ACTION_TYPES.CHANGE_VIEW_RESULTS_GRANTS_PAGE,
  createPayload<number>(),
);
export const changeViewResultsGrantsQueryAction: CreateActionType<
  ACTION_TYPES.CHANGE_VIEW_RESULTS_GRANTS_QUERY,
  Params
> = createAction(
  ACTION_TYPES.CHANGE_VIEW_RESULTS_GRANTS_QUERY,
  createPayload<Params>(),
);

export const resetViewResultsGrantsStateAction: ActionCreator<
  ACTION_TYPES.RESET_VIEW_RESULTS_GRANTS_STATE,
  () => Action<ACTION_TYPES.RESET_VIEW_RESULTS_GRANTS_STATE>
> = createAction(ACTION_TYPES.RESET_VIEW_RESULTS_GRANTS_STATE);
