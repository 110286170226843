import { environment } from "@env/environment";

import { RecaptchaSettings } from "ng-recaptcha-2";

export const CAPTCHA_URL: string = "https://www.google.com/recaptcha/api.js";
export const CAPTCHA_KEY: string = environment.captchaKey;

export const CAPTCHA_VALUE: RecaptchaSettings = {
  siteKey: CAPTCHA_KEY,
};
