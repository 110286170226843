import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import {
  IListRequest,
  ISegmentId,
  ISegmentLimit,
  ISegmentRename,
} from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";
import {
  ISegmentDuplicateInfo,
  ISetBuildingsCapData,
} from "../../../segment/interfaces/segment";

import { ISegmentByItem } from "../../../segment/models/segment-by";

enum ACTION_TYPES {
  ADD = "[LIST SEGMENTS]: Add new segment",
  ADD_ERROR = "[LIST SEGMENTS]: Add new segment error",
  ADD_SUCCESS = "[LIST SEGMENTS]: Add new segment success",

  INIT_DELETE = "[LIST SEGMENTS]: Init delete of segment",
  DELETE = "[LIST SEGMENTS]: Delete segment",
  DELETE_ERROR = "[LIST SEGMENTS]: Delete segment error",
  DELETE_SUCCESS = "[LIST SEGMENTS]: Delete segment success",

  DUPLICATE = "[LIST SEGMENTS] Duplicate segment",
  DUPLICATE_ERROR = "[LIST SEGMENTS] Duplicate segment error",
  DUPLICATE_SUCCESS = "[LIST SEGMENTS] Duplicate segment success",

  RENAME = "[LIST SEGMENTS]: Rename segment",
  RENAME_ERROR = "[LIST SEGMENTS]: Rename segment error",
  RENAME_SUCCESS = "[LIST SEGMENTS]: Rename segment success",

  SET_SEGMENT_CAP = "[LIST SEGMENTS]: Set Segment cap",
  SET_SEGMENT_CAP_ERROR = "[LIST SEGMENTS]: Set Segment cap error",
  SET_SEGMENT_CAP_SUCCESS = "[LIST SEGMENTS]: Set Segment cap success",

  SET_BUILDINGS_CAP = "[LIST SEGMENTS] Set buildings cap",
  SET_BUILDINGS_CAP_ERROR = "[LIST SEGMENTS] Set buildings cap error",
  SET_BUILDINGS_CAP_SUCCESS = "[LIST SEGMENTS] Set buildings cap success",

  LIST_SEGMENT_BY = "[LIST SEGMENTS]: segment by criteria action",
  LIST_SEGMENT_BY_ERROR = "[LIST SEGMENTS]: segment by criteria error action",
  LIST_SEGMENT_BY_SUCCESS = "[LIST SEGMENTS]: segment by criteria success action",

  LIST_SHOW_SEGMENT_BY_CONFIRM_POP_UP = "[LIST SEGMENTS]: show segment by confirm pop up",
  LIST_SHOW_SEGMENT_BY_COUNT_EXCEEDED_POP_UP = "[LIST SEGMENTS]: show segment by count exceeded pop up",
  LIST_IS_SEGMENT_BY_POSSIBLE = "[LIST SEGMENTS]: check is segment by possible",

  SEGMENT_DEDUPE_INSTITUTIONS = "[LIST SEGMENTS]: segment dedupe institutions",
  SEGMENT_DEDUPE_INSTITUTIONS_ERROR = "[LIST SEGMENTS]: segment dedupe institutions error",
  SEGMENT_DEDUPE_INSTITUTIONS_SUCCESS = "[LIST SEGMENTS]: segment dedupe institutions success",
}

export const addListSegmentAction: CreateActionType<
  ACTION_TYPES.ADD,
  IListRequest
> = createAction(ACTION_TYPES.ADD, createPayload<IListRequest>());
export const addListSegmentErrorAction: CreateActionType<
  ACTION_TYPES.ADD_ERROR,
  IServerError
> = createAction(ACTION_TYPES.ADD_ERROR, createPayload<IServerError>());
export const addListSegmentSuccessAction: ActionCreator<
  ACTION_TYPES.ADD_SUCCESS,
  () => Action<ACTION_TYPES.ADD_SUCCESS>
> = createAction(ACTION_TYPES.ADD_SUCCESS);

export const initDeleteListSegmentAction: CreateActionType<
  ACTION_TYPES.INIT_DELETE,
  ISegmentId
> = createAction(ACTION_TYPES.INIT_DELETE, createPayload<ISegmentId>());

export const deleteListSegmentAction: CreateActionType<
  ACTION_TYPES.DELETE,
  ISegmentId
> = createAction(ACTION_TYPES.DELETE, createPayload<ISegmentId>());
export const deleteListSegmentErrorAction: CreateActionType<
  ACTION_TYPES.DELETE_ERROR,
  IServerError
> = createAction(ACTION_TYPES.DELETE_ERROR, createPayload<IServerError>());
export const deleteListSegmentSuccessAction: ActionCreator<
  ACTION_TYPES.DELETE_SUCCESS,
  () => Action<ACTION_TYPES.DELETE_SUCCESS>
> = createAction(ACTION_TYPES.DELETE_SUCCESS);

export const duplicateListSegmentAction: CreateActionType<
  ACTION_TYPES.DUPLICATE,
  ISegmentDuplicateInfo
> = createAction(
  ACTION_TYPES.DUPLICATE,
  createPayload<ISegmentDuplicateInfo>(),
);
export const duplicateListSegmentErrorAction: CreateActionType<
  ACTION_TYPES.DUPLICATE_ERROR,
  IServerError
> = createAction(ACTION_TYPES.DUPLICATE_ERROR, createPayload<IServerError>());
export const duplicateListSegmentSuccessAction: ActionCreator<
  ACTION_TYPES.DUPLICATE_SUCCESS,
  () => Action<ACTION_TYPES.DUPLICATE_SUCCESS>
> = createAction(ACTION_TYPES.DUPLICATE_SUCCESS);

export const renameListSegmentAction: CreateActionType<
  ACTION_TYPES.RENAME,
  ISegmentRename
> = createAction(ACTION_TYPES.RENAME, createPayload<ISegmentRename>());
export const renameListSegmentErrorAction: CreateActionType<
  ACTION_TYPES.RENAME_ERROR,
  IServerError
> = createAction(ACTION_TYPES.RENAME_ERROR, createPayload<IServerError>());
export const renameListSegmentSuccessAction: ActionCreator<
  ACTION_TYPES.RENAME_SUCCESS,
  () => Action<ACTION_TYPES.RENAME_SUCCESS>
> = createAction(ACTION_TYPES.RENAME_SUCCESS);

export const setCapListSegmentAction: CreateActionType<
  ACTION_TYPES.SET_SEGMENT_CAP,
  ISegmentLimit
> = createAction(ACTION_TYPES.SET_SEGMENT_CAP, createPayload<ISegmentLimit>());
export const setCapListSegmentErrorAction: CreateActionType<
  ACTION_TYPES.SET_SEGMENT_CAP_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.SET_SEGMENT_CAP_ERROR,
  createPayload<IServerError>(),
);
export const setCapListSegmentSuccessAction: ActionCreator<
  ACTION_TYPES.SET_SEGMENT_CAP_SUCCESS,
  () => Action<ACTION_TYPES.SET_SEGMENT_CAP_SUCCESS>
> = createAction(ACTION_TYPES.SET_SEGMENT_CAP_SUCCESS);

export const setBuildingsSegmentCapAction: CreateActionType<
  ACTION_TYPES.SET_BUILDINGS_CAP,
  ISetBuildingsCapData
> = createAction(
  ACTION_TYPES.SET_BUILDINGS_CAP,
  createPayload<ISetBuildingsCapData>(),
);
export const setBuildingsSegmentCapErrorAction: CreateActionType<
  ACTION_TYPES.SET_BUILDINGS_CAP_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.SET_BUILDINGS_CAP_ERROR,
  createPayload<IServerError>(),
);
export const setBuildingsSegmentCapSuccessAction: ActionCreator<
  ACTION_TYPES.SET_BUILDINGS_CAP_SUCCESS,
  () => Action<ACTION_TYPES.SET_BUILDINGS_CAP_SUCCESS>
> = createAction(ACTION_TYPES.SET_BUILDINGS_CAP_SUCCESS);

export const listSegmentByAction: CreateActionType<
  ACTION_TYPES.LIST_SEGMENT_BY,
  ISegmentByItem
> = createAction(ACTION_TYPES.LIST_SEGMENT_BY, createPayload<ISegmentByItem>());
export const listSegmentByErrorAction: CreateActionType<
  ACTION_TYPES.LIST_SEGMENT_BY_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.LIST_SEGMENT_BY_ERROR,
  createPayload<IServerError>(),
);
export const listSegmentBySuccessAction: ActionCreator<
  ACTION_TYPES.LIST_SEGMENT_BY_SUCCESS,
  () => Action<ACTION_TYPES.LIST_SEGMENT_BY_SUCCESS>
> = createAction(ACTION_TYPES.LIST_SEGMENT_BY_SUCCESS);

export const listShowSegmentByConfirmPopUpAction: CreateActionType<
  ACTION_TYPES.LIST_SHOW_SEGMENT_BY_CONFIRM_POP_UP,
  ISegmentByItem
> = createAction(
  ACTION_TYPES.LIST_SHOW_SEGMENT_BY_CONFIRM_POP_UP,
  createPayload<ISegmentByItem>(),
);
export const listShowSegmentByCountExceededPopUpAction: ActionCreator<
  ACTION_TYPES.LIST_SHOW_SEGMENT_BY_COUNT_EXCEEDED_POP_UP,
  () => Action<ACTION_TYPES.LIST_SHOW_SEGMENT_BY_COUNT_EXCEEDED_POP_UP>
> = createAction(ACTION_TYPES.LIST_SHOW_SEGMENT_BY_COUNT_EXCEEDED_POP_UP);
export const listIsSegmentByPossibleAction: CreateActionType<
  ACTION_TYPES.LIST_IS_SEGMENT_BY_POSSIBLE,
  ISegmentByItem
> = createAction(
  ACTION_TYPES.LIST_IS_SEGMENT_BY_POSSIBLE,
  createPayload<ISegmentByItem>(),
);

export const segmentDedupeInstitutionsAction: CreateActionType<
  ACTION_TYPES.SEGMENT_DEDUPE_INSTITUTIONS,
  number
> = createAction(
  ACTION_TYPES.SEGMENT_DEDUPE_INSTITUTIONS,
  createPayload<number>(),
);
export const segmentDedupeInstitutionsErrorAction: CreateActionType<
  ACTION_TYPES.SEGMENT_DEDUPE_INSTITUTIONS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.SEGMENT_DEDUPE_INSTITUTIONS_ERROR,
  createPayload<IServerError>(),
);
export const segmentDedupeInstitutionsSuccessAction: ActionCreator<
  ACTION_TYPES.SEGMENT_DEDUPE_INSTITUTIONS_SUCCESS,
  () => Action<ACTION_TYPES.SEGMENT_DEDUPE_INSTITUTIONS_SUCCESS>
> = createAction(ACTION_TYPES.SEGMENT_DEDUPE_INSTITUTIONS_SUCCESS);
