import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { IGrantProfile } from "../../interfaces";

enum ACTION_TYPES {
  GET = "[GRANT PROFILE] Get",
  GET_ERROR = "[GRANT PROFILE] Get error",
  GET_SUCCESS = "[GRANT PROFILE] Get success",

  SET_SPINNER_STATE = "[GRANT PROFILE] Set spinner state",

  RESET = "[GRANT PROFILE] Reset state",
}

export const getGrantProfileAction: CreateActionType<ACTION_TYPES.GET, number> =
  createAction(ACTION_TYPES.GET, createPayload<number>());
export const getGrantProfileErrorAction: CreateActionType<
  ACTION_TYPES.GET_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_ERROR, createPayload<IServerError>());
export const getGrantProfileSuccessAction: CreateActionType<
  ACTION_TYPES.GET_SUCCESS,
  IGrantProfile
> = createAction(ACTION_TYPES.GET_SUCCESS, createPayload<IGrantProfile>());

export const setGrantProfileSpinnerStateAction: CreateActionType<
  ACTION_TYPES.SET_SPINNER_STATE,
  boolean
> = createAction(ACTION_TYPES.SET_SPINNER_STATE, createPayload<boolean>());

export const resetGrantProfileStateAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => Action<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
