import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IMultiCodesError } from "@shared/interfaces/server-error";

import { IAddMultiCodesData } from "../../models/codes";

enum ACTION_TYPES {
  ADD = "[GEO MULTI CODES] Add codes",
  ADD_ERROR = "[GEO MULTI CODES] Add codes Error",
  RESET_ERROR = "[GEO MULTI CODES] Reset codes Error",
  ADD_SUCCESS = "[GEO MULTI CODES] Add codes Success",

  RESET = "[GEO MULTI CODES] Reset",
}

export const addGeoMultiCodesAction: CreateActionType<
  ACTION_TYPES.ADD,
  IAddMultiCodesData
> = createAction(ACTION_TYPES.ADD, createPayload<IAddMultiCodesData>());
export const addGeoMultiCodesErrorAction: CreateActionType<
  ACTION_TYPES.ADD_ERROR,
  IMultiCodesError
> = createAction(ACTION_TYPES.ADD_ERROR, createPayload<IMultiCodesError>());
export const resetAddGeoMultiCodesErrorAction: ActionCreator<
  ACTION_TYPES.RESET_ERROR,
  () => Action<ACTION_TYPES.RESET_ERROR>
> = createAction(ACTION_TYPES.RESET_ERROR);
export const addGeoMultiCodesSuccessAction: ActionCreator<
  ACTION_TYPES.ADD_SUCCESS,
  () => Action<ACTION_TYPES.ADD_SUCCESS>
> = createAction(ACTION_TYPES.ADD_SUCCESS);

export const resetGeoMultiCodesAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => Action<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
