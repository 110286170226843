import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { IGrantProfileAlertHistory } from "../../interfaces/alert-history";

enum ACTION_TYPES {
  GET = "[GRANT PROFILE ALERT HISTORY] get",
  GET_ERROR = "[GRANT PROFILE ALERT HISTORY] get error",
  GET_SUCCESS = "[GRANT PROFILE ALERT HISTORY] get success",

  TOGGLE = "[GRANT PROFILE ALERT HISTORY] toggle",

  SORT = "[GRANT PROFILE ALERT HISTORY] sort",
  ORDER = "[GRANT PROFILE ALERT HISTORY] order",

  RESET = "[GRANT PROFILE ALERT HISTORY] reset state",
}

export const getGrantProfileAlertHistoryAction: CreateActionType<
  ACTION_TYPES.GET,
  number
> = createAction(ACTION_TYPES.GET, createPayload<number>());
export const getGrantProfileAlertHistoryErrorAction: CreateActionType<
  ACTION_TYPES.GET_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_ERROR, createPayload<IServerError>());
export const getGrantProfileAlertHistorySuccessAction: CreateActionType<
  ACTION_TYPES.GET_SUCCESS,
  IGrantProfileAlertHistory
> = createAction(
  ACTION_TYPES.GET_SUCCESS,
  createPayload<IGrantProfileAlertHistory>(),
);

export const sortGrantProfileAlertHistoryAction: CreateActionType<
  ACTION_TYPES.SORT,
  string
> = createAction(ACTION_TYPES.SORT, createPayload<string>());
export const orderGrantProfileAlertHistoryAction: CreateActionType<
  ACTION_TYPES.ORDER,
  string
> = createAction(ACTION_TYPES.ORDER, createPayload<string>());

export const resetGrantProfileAlertsHistoryAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => Action<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
