import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IBidCategoriesPayload,
  IBidGroupsPayload,
  IEntityAddPayload,
  IEntityRemovePayload,
} from "../../interfaces/bid-categories";
import { IBrowseEntities } from "../../interfaces/browse";

enum ACTION_TYPES {
  GET_GROUPS = "[Bid Targeting] Get Groups",
  RESET_GROUPS = "[Bid Targeting] Reset Groups",
  GET_GROUPS_ERROR = "[Bid Targeting] Get Groups Error",
  GET_GROUPS_SUCCESS = "[Bid Targeting] Get Groups Success",

  GET_CATEGORIES = "[Bid Targeting] Get Categories",
  RESET_CATEGORIES = "[Bid Targeting] Reset Categories",
  GET_CATEGORIES_ERROR = "[Bid Targeting] Get Categories Error",
  GET_CATEGORIES_SUCCESS = "[Bid Targeting] Get Categories Success",

  ADD_ENTITY_AND_RELOAD = "[Bid Targeting] Add Entity and Reload",
  ADD_ENTITY_ERROR = "[Bid Targeting] Add Entity Error",
  ADD_ENTITY_SUCCESS = "[Bid Targeting] Add Entity Success",

  REMOVE_ENTITY_AND_RELOAD = "[Bid Targeting] Remove Entity and Reload",
  REMOVE_ENTITY_ERROR = "[Bid Targeting] Remove Entity Error",
  REMOVE_ENTITY_SUCCESS = "[Bid Targeting] Remove Entity Success",

  RESET_STATE = "[Bid Targeting] Reset Bid Categories State",
}

export const getGroupsAction: CreateActionType<
  ACTION_TYPES.GET_GROUPS,
  IBidGroupsPayload
> = createAction(ACTION_TYPES.GET_GROUPS, createPayload<IBidGroupsPayload>());
export const resetGroupsAction: CreateActionType<
  ACTION_TYPES.RESET_GROUPS,
  boolean
> = createAction(ACTION_TYPES.RESET_GROUPS, createPayload<boolean>(true));
export const getGroupsErrorAction: CreateActionType<
  ACTION_TYPES.GET_GROUPS_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_GROUPS_ERROR, createPayload<IServerError>());
export const getGroupsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_GROUPS_SUCCESS,
  IBrowseEntities
> = createAction(
  ACTION_TYPES.GET_GROUPS_SUCCESS,
  createPayload<IBrowseEntities>(),
);

export const getCategoriesAction: CreateActionType<
  ACTION_TYPES.GET_CATEGORIES,
  IBidCategoriesPayload
> = createAction(
  ACTION_TYPES.GET_CATEGORIES,
  createPayload<IBidCategoriesPayload>(),
);
export const resetCategoriesAction: CreateActionType<
  ACTION_TYPES.RESET_CATEGORIES,
  boolean
> = createAction(ACTION_TYPES.RESET_CATEGORIES, createPayload<boolean>(true));
export const getCategoriesErrorAction: CreateActionType<
  ACTION_TYPES.GET_CATEGORIES_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_CATEGORIES_ERROR,
  createPayload<IServerError>(),
);
export const getCategoriesSuccessAction: CreateActionType<
  ACTION_TYPES.GET_CATEGORIES_SUCCESS,
  IBrowseEntities
> = createAction(
  ACTION_TYPES.GET_CATEGORIES_SUCCESS,
  createPayload<IBrowseEntities>(),
);

export const addEntityAndReloadAction: CreateActionType<
  ACTION_TYPES.ADD_ENTITY_AND_RELOAD,
  IEntityAddPayload
> = createAction(
  ACTION_TYPES.ADD_ENTITY_AND_RELOAD,
  createPayload<IEntityAddPayload>(),
);
export const addEntityErrorAction: CreateActionType<
  ACTION_TYPES.ADD_ENTITY_ERROR,
  IServerError
> = createAction(ACTION_TYPES.ADD_ENTITY_ERROR, createPayload<IServerError>());
export const addEntitySuccessAction: ActionCreator<
  ACTION_TYPES.ADD_ENTITY_SUCCESS,
  () => Action<ACTION_TYPES.ADD_ENTITY_SUCCESS>
> = createAction(ACTION_TYPES.ADD_ENTITY_SUCCESS);

export const removeEntityAndReloadAction: CreateActionType<
  ACTION_TYPES.REMOVE_ENTITY_AND_RELOAD,
  IEntityRemovePayload
> = createAction(
  ACTION_TYPES.REMOVE_ENTITY_AND_RELOAD,
  createPayload<IEntityRemovePayload>(),
);
export const removeEntityErrorAction: CreateActionType<
  ACTION_TYPES.REMOVE_ENTITY_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.REMOVE_ENTITY_ERROR,
  createPayload<IServerError>(),
);

export const removeEntitySuccessAction: ActionCreator<
  ACTION_TYPES.REMOVE_ENTITY_SUCCESS,
  () => Action<ACTION_TYPES.REMOVE_ENTITY_SUCCESS>
> = createAction(ACTION_TYPES.REMOVE_ENTITY_SUCCESS);

export const resetBidCategoriesStateAction: ActionCreator<
  ACTION_TYPES.RESET_STATE,
  () => Action<ACTION_TYPES.RESET_STATE>
> = createAction(ACTION_TYPES.RESET_STATE);
