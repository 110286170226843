// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  padding: 12px 0 10px 0;
  border-top: 1px solid #6B6D6F;
  display: flex;
  justify-content: center;
}

.btn {
  padding: 6px 21px 5px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImRyb3AtZG93bi1jbG9zZS1mb290ZXIuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRSxzQkFBQTtFQUNBLDZCQUFBO0VBQ0EsYUFBQTtFQUNBLHVCQUFBO0FBREY7O0FBSUE7RUFDRSxxQkFBQTtBQURGIiwiZmlsZSI6ImRyb3AtZG93bi1jbG9zZS1mb290ZXIuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0ICcuLi8uLi8uLi8uLi8uLi9hc3NldHMvc3R5bGVzL3ZhcmlhYmxlcyc7XG5cbi5mb290ZXIge1xuICBwYWRkaW5nOiAxMnB4IDAgMTBweCAwO1xuICBib3JkZXItdG9wOiAxcHggc29saWQgJGdyYXk7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xufVxuXG4uYnRuIHtcbiAgcGFkZGluZzogNnB4IDIxcHggNXB4O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/app/ui/drop-down/components/drop-down-close-footer/drop-down-close-footer.component.scss"],"names":[],"mappings":"AAEA;EACE,sBAAA;EACA,6BAAA;EACA,aAAA;EACA,uBAAA;AADF;;AAIA;EACE,qBAAA;AADF;AACA,ooBAAooB","sourcesContent":["@import '../../../../../assets/styles/variables';\n\n.footer {\n  padding: 12px 0 10px 0;\n  border-top: 1px solid $gray;\n  display: flex;\n  justify-content: center;\n}\n\n.btn {\n  padding: 6px 21px 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
