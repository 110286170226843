import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { ISchool } from "../../interfaces/school-profile";

enum ACTION_TYPES {
  GET_SCHOOL_PROFILE = "[SCHOOL PROFILE]: Get school profile",
  GET_SCHOOL_PROFILE_ERROR = "[SCHOOL PROFILE]: Get school profile error",
  GET_SCHOOL_PROFILE_SUCCESS = "[SCHOOL PROFILE] Get school profile success",

  RESET_SCHOOL_PROFILE = "[SCHOOL PROFILE]: Reset school profile state",
}

export const getSchoolProfileAction: CreateActionType<
  ACTION_TYPES.GET_SCHOOL_PROFILE,
  { id: number }
> = createAction(
  ACTION_TYPES.GET_SCHOOL_PROFILE,
  createPayload<{ id: number }>(),
);
export const getSchoolProfileErrorAction: CreateActionType<
  ACTION_TYPES.GET_SCHOOL_PROFILE_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_SCHOOL_PROFILE_ERROR,
  createPayload<IServerError>(),
);
export const getSchoolProfileSuccessAction: CreateActionType<
  ACTION_TYPES.GET_SCHOOL_PROFILE_SUCCESS,
  ISchool
> = createAction(
  ACTION_TYPES.GET_SCHOOL_PROFILE_SUCCESS,
  createPayload<ISchool>(),
);

export const resetSchoolProfileAction: ActionCreator<
  ACTION_TYPES.RESET_SCHOOL_PROFILE,
  () => Action<ACTION_TYPES.RESET_SCHOOL_PROFILE>
> = createAction(ACTION_TYPES.RESET_SCHOOL_PROFILE);
