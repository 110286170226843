import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { IBidProfile } from "../../interfaces/bid-profile";

enum ACTION_TYPES {
  GET = "[BID PROFILE] Get",
  GET_ERROR = "[BID PROFILE] Get error",
  GET_SUCCESS = "[BID PROFILE] Get success",

  RESET = "[BID PROFILE] Reset state",
}

export const getBidProfileAction: CreateActionType<ACTION_TYPES.GET, number> =
  createAction(ACTION_TYPES.GET, createPayload<number>());
export const getBidProfileErrorAction: CreateActionType<
  ACTION_TYPES.GET_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_ERROR, createPayload<IServerError>());
export const getBidProfileSuccessAction: CreateActionType<
  ACTION_TYPES.GET_SUCCESS,
  IBidProfile
> = createAction(ACTION_TYPES.GET_SUCCESS, createPayload<IBidProfile>());

export const resetBidProfileStateAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => Action<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
