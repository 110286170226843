import { Params } from "@angular/router";
import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import {
  IPersonnelRecords,
  IProductResultsResponse,
} from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

enum ACTION_TYPES {
  GET_QUICK_SEARCH_ALL_PERSONNEL = "[QUICK SEARCH] Get All Personnel",
  GET_QUICK_SEARCH_ALL_PERSONNEL_ERROR = "[QUICK SEARCH] Get All Personnel error",
  GET_QUICK_SEARCH_ALL_PERSONNEL_SUCCESS = "[QUICK SEARCH] Get All Personnel success",

  CHANGE_QUICK_SEARCH_ALL_PERSONNEL_QUERY = "[QUICK SEARCH] Change all Personnel query",
  CHANGE_QUICK_SEARCH_ALL_PERSONNEL_PAGE = "[QUICK SEARCH] Change all Personnel page",

  RESET_QUICK_SEARCH_ALL_PERSONNEL_STATE = "[QUICK SEARCH] Reset All Personnel state",
}

export const getQuickSearchAllPersonnelAction: ActionCreator<
  ACTION_TYPES.GET_QUICK_SEARCH_ALL_PERSONNEL,
  () => Action<ACTION_TYPES.GET_QUICK_SEARCH_ALL_PERSONNEL>
> = createAction(ACTION_TYPES.GET_QUICK_SEARCH_ALL_PERSONNEL);
export const getQuickSearchAllPersonnelErrorAction: CreateActionType<
  ACTION_TYPES.GET_QUICK_SEARCH_ALL_PERSONNEL_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_QUICK_SEARCH_ALL_PERSONNEL_ERROR,
  createPayload<IServerError>(),
);
export const getQuickSearchAllPersonnelSuccessAction: CreateActionType<
  ACTION_TYPES.GET_QUICK_SEARCH_ALL_PERSONNEL_SUCCESS,
  IProductResultsResponse<IPersonnelRecords>
> = createAction(
  ACTION_TYPES.GET_QUICK_SEARCH_ALL_PERSONNEL_SUCCESS,
  createPayload<IProductResultsResponse<IPersonnelRecords>>(),
);

export const changeQuickSearchAllPersonnelQueryAction: CreateActionType<
  ACTION_TYPES.CHANGE_QUICK_SEARCH_ALL_PERSONNEL_QUERY,
  Params
> = createAction(
  ACTION_TYPES.CHANGE_QUICK_SEARCH_ALL_PERSONNEL_QUERY,
  createPayload<Params>(),
);
export const changeQuickSearchAllPersonnelPageAction: CreateActionType<
  ACTION_TYPES.CHANGE_QUICK_SEARCH_ALL_PERSONNEL_PAGE,
  number
> = createAction(
  ACTION_TYPES.CHANGE_QUICK_SEARCH_ALL_PERSONNEL_PAGE,
  createPayload<number>(),
);
export const resetQuickSearchAllPersonnelStateAction: ActionCreator<
  ACTION_TYPES.RESET_QUICK_SEARCH_ALL_PERSONNEL_STATE,
  () => Action<ACTION_TYPES.RESET_QUICK_SEARCH_ALL_PERSONNEL_STATE>
> = createAction(ACTION_TYPES.RESET_QUICK_SEARCH_ALL_PERSONNEL_STATE);
