import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import {
  IColumnMoveTo,
  IEditColumn,
  IFilterByAddOns,
  IOutputColumnsResponse,
  IOutputSettings,
  IToggleColumns,
} from "@modules/cdl/cdl-list-customize/interfaces";
import { IListData } from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";

import { COLUMN_TYPE } from "@modules/cdl/cdl-list-customize/constants";
import { SEGMENT_TYPES } from "@modules/cdl/cdl-list/constants";

enum ACTION_TYPES {
  GET_CDL_LIST = "[CDL LIST CUSTOMIZE] Get list",
  GET_CDL_LIST_ERROR = "[CDL LIST CUSTOMIZE] Get list error",
  GET_CDL_LIST_SUCCESS = "[CDL LIST CUSTOMIZE] Get list success",

  SET_ACTIVE_TAB = "[CDL LIST CUSTOMIZE] set active tab",

  GET_OUTPUT_SETTINGS = "[CDL LIST CUSTOMIZE] get output settings",
  GET_OUTPUT_SETTINGS_ERROR = "[CDL LIST CUSTOMIZE] get output settings error",
  GET_OUTPUT_SETTINGS_SUCCESS = "[CDL LIST CUSTOMIZE] get output settings success",

  EDIT_OUTPUT_SETTINGS = "[CDL LIST CUSTOMIZE] edit output settings",
  EDIT_OUTPUT_SETTINGS_ERROR = "[CDL LIST CUSTOMIZE] edit output settings error",
  EDIT_OUTPUT_SETTINGS_SUCCESS = "[CDL LIST CUSTOMIZE] edit output settings success",

  GET_COLUMNS = "[CDL LIST CUSTOMIZE] get columns",
  GET_COLUMNS_ERROR = "[CDL LIST CUSTOMIZE] get columns error",
  GET_COLUMNS_SUCCESS = "[CDL LIST CUSTOMIZE] get columns success",

  EDIT_COLUMN = "[CDL LIST CUSTOMIZE] edit column",
  EDIT_COLUMN_ERROR = "[CDL LIST CUSTOMIZE] edit column error",
  EDIT_COLUMN_SUCCESS = "[CDL LIST CUSTOMIZE] edit column success",

  COLUMN_MOVE_TO = "[CDL LIST CUSTOMIZE] column move to",
  COLUMN_MOVE_TO_ERROR = "[CDL LIST CUSTOMIZE] column move to error",
  COLUMN_MOVE_TO_SUCCESS = "[CDL LIST CUSTOMIZE] column move to success",

  TOGGLE_COLUMNS = "[CDL LIST CUSTOMIZE] toggle columns",
  TOGGLE_COLUMNS_ERROR = "[CDL LIST CUSTOMIZE] toggle columns error",
  TOGGLE_COLUMNS_SUCCESS = "[CDL LIST CUSTOMIZE] toggle columns success",

  CHANGE_FILTER_BY_ADD_ONS = "[CDL LIST CUSTOMIZE] change filter by add ons",
  RESET_FILTER_ADD_ONS = "[CDL LIST CUSTOMIZE] reset filter add ons",

  RESET = "[CDL LIST CUSTOMIZE] reset state",
}

export const getCDLListCustomizeAction: CreateActionType<
  ACTION_TYPES.GET_CDL_LIST,
  number
> = createAction(ACTION_TYPES.GET_CDL_LIST, createPayload<number>());
export const getCDLListCustomizeErrorAction: CreateActionType<
  ACTION_TYPES.GET_CDL_LIST_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_CDL_LIST_ERROR,
  createPayload<IServerError>(),
);
export const getCDLListCustomizeSuccessAction: CreateActionType<
  ACTION_TYPES.GET_CDL_LIST_SUCCESS,
  IListData
> = createAction(ACTION_TYPES.GET_CDL_LIST_SUCCESS, createPayload<IListData>());

export const setCustomizeActiveTabAction: CreateActionType<
  ACTION_TYPES.SET_ACTIVE_TAB,
  SEGMENT_TYPES
> = createAction(ACTION_TYPES.SET_ACTIVE_TAB, createPayload<SEGMENT_TYPES>());

export const getOutputSettingsAction: CreateActionType<
  ACTION_TYPES.GET_OUTPUT_SETTINGS,
  number
> = createAction(ACTION_TYPES.GET_OUTPUT_SETTINGS, createPayload<number>());
export const getOutputSettingsErrorAction: CreateActionType<
  ACTION_TYPES.GET_OUTPUT_SETTINGS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_OUTPUT_SETTINGS_ERROR,
  createPayload<IServerError>(),
);
export const getOutputSettingsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_OUTPUT_SETTINGS_SUCCESS,
  IOutputSettings
> = createAction(
  ACTION_TYPES.GET_OUTPUT_SETTINGS_SUCCESS,
  createPayload<IOutputSettings>(),
);

export const editOutputSettingsAction: CreateActionType<
  ACTION_TYPES.EDIT_OUTPUT_SETTINGS,
  IOutputSettings
> = createAction(
  ACTION_TYPES.EDIT_OUTPUT_SETTINGS,
  createPayload<IOutputSettings>(),
);
export const editOutputSettingsErrorAction: CreateActionType<
  ACTION_TYPES.EDIT_OUTPUT_SETTINGS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.EDIT_OUTPUT_SETTINGS_ERROR,
  createPayload<IServerError>(),
);
export const editOutputSettingsSuccessAction: ActionCreator<
  ACTION_TYPES.EDIT_OUTPUT_SETTINGS_SUCCESS,
  () => Action<ACTION_TYPES.EDIT_OUTPUT_SETTINGS_SUCCESS>
> = createAction(ACTION_TYPES.EDIT_OUTPUT_SETTINGS_SUCCESS);

export const getColumnsAction: ActionCreator<
  ACTION_TYPES.GET_COLUMNS,
  () => Action<ACTION_TYPES.GET_COLUMNS>
> = createAction(ACTION_TYPES.GET_COLUMNS);
export const getColumnsErrorAction: CreateActionType<
  ACTION_TYPES.GET_COLUMNS_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_COLUMNS_ERROR, createPayload<IServerError>());
export const getColumnsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_COLUMNS_SUCCESS,
  IOutputColumnsResponse
> = createAction(
  ACTION_TYPES.GET_COLUMNS_SUCCESS,
  createPayload<IOutputColumnsResponse>(),
);

export const editColumnAction: CreateActionType<
  ACTION_TYPES.EDIT_COLUMN,
  IEditColumn
> = createAction(ACTION_TYPES.EDIT_COLUMN, createPayload<IEditColumn>());
export const editColumnErrorAction: CreateActionType<
  ACTION_TYPES.EDIT_COLUMN_ERROR,
  IServerError
> = createAction(ACTION_TYPES.EDIT_COLUMN_ERROR, createPayload<IServerError>());
export const editColumnSuccessAction: ActionCreator<
  ACTION_TYPES.EDIT_COLUMN_SUCCESS,
  () => Action<ACTION_TYPES.EDIT_COLUMN_SUCCESS>
> = createAction(ACTION_TYPES.EDIT_COLUMN_SUCCESS);

export const columnMoveToAction: CreateActionType<
  ACTION_TYPES.COLUMN_MOVE_TO,
  IColumnMoveTo
> = createAction(ACTION_TYPES.COLUMN_MOVE_TO, createPayload<IColumnMoveTo>());
export const columnMoveToErrorAction: CreateActionType<
  ACTION_TYPES.COLUMN_MOVE_TO_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.COLUMN_MOVE_TO_ERROR,
  createPayload<IServerError>(),
);
export const columnMoveToSuccessAction: ActionCreator<
  ACTION_TYPES.COLUMN_MOVE_TO_SUCCESS,
  () => Action<ACTION_TYPES.COLUMN_MOVE_TO_SUCCESS>
> = createAction(ACTION_TYPES.COLUMN_MOVE_TO_SUCCESS);

export const toggleColumnsAction: CreateActionType<
  ACTION_TYPES.TOGGLE_COLUMNS,
  IToggleColumns
> = createAction(ACTION_TYPES.TOGGLE_COLUMNS, createPayload<IToggleColumns>());
export const toggleColumnsErrorAction: CreateActionType<
  ACTION_TYPES.TOGGLE_COLUMNS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.TOGGLE_COLUMNS_ERROR,
  createPayload<IServerError>(),
);
export const toggleColumnsSuccessAction: ActionCreator<
  ACTION_TYPES.TOGGLE_COLUMNS_SUCCESS,
  () => Action<ACTION_TYPES.TOGGLE_COLUMNS_SUCCESS>
> = createAction(ACTION_TYPES.TOGGLE_COLUMNS_SUCCESS);

export const changeFilterByAddOnsAction: CreateActionType<
  ACTION_TYPES.CHANGE_FILTER_BY_ADD_ONS,
  IFilterByAddOns
> = createAction(
  ACTION_TYPES.CHANGE_FILTER_BY_ADD_ONS,
  createPayload<IFilterByAddOns>(),
);
export const resetFilterAddOnsAction: CreateActionType<
  ACTION_TYPES.RESET_FILTER_ADD_ONS,
  COLUMN_TYPE
> = createAction(
  ACTION_TYPES.RESET_FILTER_ADD_ONS,
  createPayload<COLUMN_TYPE>(),
);

export const resetCDLListCustomizeAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => Action<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
