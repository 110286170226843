import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import {
  ISignUpByInvitePayload,
  ISignUpByInviteSuccessPayload,
} from "../../interfaces/formsActionsData";

enum ACTION_TYPES {
  SIGN_UP = "[AUTH BY INVITE] sign up",
  SIGN_UP_ERROR = "[AUTH BY INVITE] sign up error",
  SIGN_UP_SUCCESS = "[AUTH BY INVITE] sign up success",

  RESET = "[AUTH BY INVITE] reset state",

  VERIFY_INVITE_ERROR = "[AUTH BY INVITE] VERIFY_INVITE_ERROR",
  VERIFY_INVITE_SUCCESS = "[AUTH BY INVITE] VERIFY_INVITE_SUCCESS",
  VERIFY_INVITE = "[AUTH BY INVITE] VERIFY_INVITE",
  SIGN_UP_CHECK_USER_ERROR = "[AUTH BY INVITE] SIGN_UP_CHECK_USER_ERROR",
}

export const signUpByInviteAction: CreateActionType<
  ACTION_TYPES.SIGN_UP,
  ISignUpByInvitePayload
> = createAction(ACTION_TYPES.SIGN_UP, createPayload<ISignUpByInvitePayload>());
export const signUpByInviteErrorAction: CreateActionType<
  ACTION_TYPES.SIGN_UP_ERROR,
  IServerError
> = createAction(ACTION_TYPES.SIGN_UP_ERROR, createPayload<IServerError>());
export const signUpByInviteSuccessAction: CreateActionType<
  ACTION_TYPES.SIGN_UP_SUCCESS,
  ISignUpByInviteSuccessPayload
> = createAction(
  ACTION_TYPES.SIGN_UP_SUCCESS,
  createPayload<ISignUpByInviteSuccessPayload>(),
);
export const signUpByInviteUserNameErrorAction: CreateActionType<
  ACTION_TYPES.SIGN_UP_CHECK_USER_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.SIGN_UP_CHECK_USER_ERROR,
  createPayload<IServerError>(),
);

export const resetSignUpByInviteStateAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => Action<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);

export const verifyInviteAction: ActionCreator<
  ACTION_TYPES.VERIFY_INVITE,
  () => Action<ACTION_TYPES.VERIFY_INVITE>
> = createAction(ACTION_TYPES.VERIFY_INVITE);
export const verifyInviteErrorAction: CreateActionType<
  ACTION_TYPES.VERIFY_INVITE_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.VERIFY_INVITE_ERROR,
  createPayload<IServerError>(),
);
export const verifyInviteSuccessAction: ActionCreator<
  ACTION_TYPES.VERIFY_INVITE_SUCCESS,
  () => Action<ACTION_TYPES.VERIFY_INVITE_SUCCESS>
> = createAction(ACTION_TYPES.VERIFY_INVITE_SUCCESS);
