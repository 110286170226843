import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { ICollege } from "../../interfaces";

enum ACTION_TYPES {
  GET = "[COLLEGE PROFILE] Get",
  GET_ERROR = "[COLLEGE PROFILE] Get error",
  GET_SUCCESS = "[COLLEGE PROFILE] Get success",

  RESET = "[COLLEGE PROFILE] Reset state",
}

export const getCollegeProfileAction: CreateActionType<
  ACTION_TYPES.GET,
  number
> = createAction(ACTION_TYPES.GET, createPayload<number>());
export const getCollegeProfileErrorAction: CreateActionType<
  ACTION_TYPES.GET_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_ERROR, createPayload<IServerError>());
export const getCollegeProfileSuccessAction: CreateActionType<
  ACTION_TYPES.GET_SUCCESS,
  ICollege
> = createAction(ACTION_TYPES.GET_SUCCESS, createPayload<ICollege>());

export const resetCollegeProfileStateAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => Action<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
