import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IDataListSummary } from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";
import {
  ICDLListAppend,
  ICDLListAppends,
  IToggleCDLListAppendField,
} from "../../../cdl-list-appends/interfaces";

enum ACTION_TYPES {
  GET_FIELDS = "[CDL LIST APPENDS] get fields",
  GET_FIELDS_ERROR = "[CDL LIST APPENDS] get fields error",
  GET_FIELDS_SUCCESS = "[CDL LIST APPENDS] get fields success",

  SELECT_APPEND = "[CDL LIST APPENDS] select append",
  RESET_SELECTED_APPENDS = "[CDL LIST APPENDS] reset selected appends",

  TOGGLE = "[CDL LIST APPENDS] toggle field",
  TOGGLE_ERROR = "[CDL LIST APPENDS] toggle field error",
  TOGGLE_SUCCESS = "[CDL LIST APPENDS] toggle field success",

  GET_CDL_LIST_DATA = "[CDL LIST APPENDS] Get list data",
  GET_CDL_LIST_DATA_ERROR = "[CDL LIST APPENDS] Get list data error",
  GET_CDL_LIST_DATA_SUCCESS = "[CDL LIST APPENDS] Get list data success",

  RESET = "[CDL LIST APPENDS] reset state",
}

export const getCDLListAppendFieldsAction: CreateActionType<
  ACTION_TYPES.GET_FIELDS,
  number
> = createAction(ACTION_TYPES.GET_FIELDS, createPayload<number>());
export const getCDLListAppendFieldsErrorAction: CreateActionType<
  ACTION_TYPES.GET_FIELDS_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_FIELDS_ERROR, createPayload<IServerError>());
export const getCDLListAppendFieldsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_FIELDS_SUCCESS,
  ICDLListAppends
> = createAction(
  ACTION_TYPES.GET_FIELDS_SUCCESS,
  createPayload<ICDLListAppends>(),
);

export const selectCDLListAppendsAction: CreateActionType<
  ACTION_TYPES.SELECT_APPEND,
  ICDLListAppend
> = createAction(ACTION_TYPES.SELECT_APPEND, createPayload<ICDLListAppend>());
export const resetSelectedCDLListAppendsAction: ActionCreator<
  ACTION_TYPES.RESET_SELECTED_APPENDS,
  () => Action<ACTION_TYPES.RESET_SELECTED_APPENDS>
> = createAction(ACTION_TYPES.RESET_SELECTED_APPENDS);

export const toggleCDLListAppendFieldAction: CreateActionType<
  ACTION_TYPES.TOGGLE,
  IToggleCDLListAppendField
> = createAction(
  ACTION_TYPES.TOGGLE,
  createPayload<IToggleCDLListAppendField>(),
);
export const toggleCDLListAppendFieldErrorAction: CreateActionType<
  ACTION_TYPES.TOGGLE_ERROR,
  IServerError
> = createAction(ACTION_TYPES.TOGGLE_ERROR, createPayload<IServerError>());
export const toggleCDLListAppendFieldSuccessAction: CreateActionType<
  ACTION_TYPES.TOGGLE_SUCCESS,
  IToggleCDLListAppendField
> = createAction(
  ACTION_TYPES.TOGGLE_SUCCESS,
  createPayload<IToggleCDLListAppendField>(),
);

export const getCDLAppendsListDataAction: CreateActionType<
  ACTION_TYPES.GET_CDL_LIST_DATA,
  number
> = createAction(ACTION_TYPES.GET_CDL_LIST_DATA, createPayload<number>());
export const getCDLAppendsListDataErrorAction: CreateActionType<
  ACTION_TYPES.GET_CDL_LIST_DATA_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_CDL_LIST_DATA_ERROR,
  createPayload<IServerError>(),
);
export const getCDLAppendsListDataSuccessAction: CreateActionType<
  ACTION_TYPES.GET_CDL_LIST_DATA_SUCCESS,
  IDataListSummary
> = createAction(
  ACTION_TYPES.GET_CDL_LIST_DATA_SUCCESS,
  createPayload<IDataListSummary>(),
);

export const resetCDLListAppendsAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => Action<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
