import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IDataListSummary, IListRequest } from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";
import { ISegmentData } from "../../../segment/interfaces/segment";

enum ACTION_TYPES {
  GET_LIST_DATA = "[LIST SUMMARY]: get data",
  GET_LIST_DATA_ERROR = "[LIST SUMMARY]: get data error",
  GET_LIST_DATA_SUCCESS = "[LIST SUMMARY]: get data success",

  GET_SEGMENT_DATA = "[LIST SUMMARY] load segment data",
  GET_SEGMENT_DATA_ERROR = "[LIST SUMMARY] load segment data error",
  GET_SEGMENT_DATA_SUCCESS = "[LIST SUMMARY] load segment data success",

  DELETE = "[LIST SUMMARY]: remove list",
  DELETE_ERROR = "[LIST SUMMARY]: remove list error",
  DELETE_SUCCESS = "[LIST SUMMARY]: remove list success",

  DUPLICATE = "[LIST SUMMARY]: duplicate list",
  DUPLICATE_ERROR = "[LIST SUMMARY]: duplicate list error",
  DUPLICATE_SUCCESS = "[LIST SUMMARY]: duplicate list success",

  RESET = "[LIST SUMMARY]: Reset SUMMARY",
}

export const getListSummaryAction: CreateActionType<
  ACTION_TYPES.GET_LIST_DATA,
  IListRequest
> = createAction(ACTION_TYPES.GET_LIST_DATA, createPayload<IListRequest>());
export const getListSummaryErrorAction: CreateActionType<
  ACTION_TYPES.GET_LIST_DATA_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_LIST_DATA_ERROR,
  createPayload<IServerError>(),
);
export const getListSummarySuccessAction: CreateActionType<
  ACTION_TYPES.GET_LIST_DATA_SUCCESS,
  IDataListSummary
> = createAction(
  ACTION_TYPES.GET_LIST_DATA_SUCCESS,
  createPayload<IDataListSummary>(),
);

export const getSegmentDataAction: CreateActionType<
  ACTION_TYPES.GET_SEGMENT_DATA,
  number
> = createAction(ACTION_TYPES.GET_SEGMENT_DATA, createPayload<number>());
export const getSegmentDataErrorAction: CreateActionType<
  ACTION_TYPES.GET_SEGMENT_DATA_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_SEGMENT_DATA_ERROR,
  createPayload<IServerError>(),
);
export const getSegmentDataSuccessAction: CreateActionType<
  ACTION_TYPES.GET_SEGMENT_DATA_SUCCESS,
  ISegmentData
> = createAction(
  ACTION_TYPES.GET_SEGMENT_DATA_SUCCESS,
  createPayload<ISegmentData>(),
);

export const removeSummaryListAction: CreateActionType<
  ACTION_TYPES.DELETE,
  IListRequest
> = createAction(ACTION_TYPES.DELETE, createPayload<IListRequest>());
export const removeSummaryListErrorAction: CreateActionType<
  ACTION_TYPES.DELETE_ERROR,
  IServerError
> = createAction(ACTION_TYPES.DELETE_ERROR, createPayload<IServerError>());
export const removeSummaryListSuccessAction: ActionCreator<
  ACTION_TYPES.DELETE_SUCCESS,
  () => Action<ACTION_TYPES.DELETE_SUCCESS>
> = createAction(ACTION_TYPES.DELETE_SUCCESS);

export const duplicateSummaryListAction: CreateActionType<
  ACTION_TYPES.DUPLICATE,
  IListRequest
> = createAction(ACTION_TYPES.DUPLICATE, createPayload<IListRequest>());
export const duplicateSummaryListErrorAction: CreateActionType<
  ACTION_TYPES.DUPLICATE_ERROR,
  IServerError
> = createAction(ACTION_TYPES.DUPLICATE_ERROR, createPayload<IServerError>());
export const duplicateSummaryListSuccessAction: CreateActionType<
  ACTION_TYPES.DUPLICATE_SUCCESS,
  number
> = createAction(ACTION_TYPES.DUPLICATE_SUCCESS, createPayload<number>()); // listId

export const resetListSummaryAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => Action<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
