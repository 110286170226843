import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import {
  IDashboardList,
  IListData,
  IListIdWithSelectedRecordType,
} from "@shared/interfaces/list";
import { IProductsResponse, IProductRequest } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";
import {
  IListAssign,
  IProductNavigationPayload,
  IRenameListPayload,
} from "../../../dashboard/interfaces";
import {
  IChangePagePayload,
  ICDLListChangeStatusPayload,
  IDashboardDeleteEntity,
  IExportDashboardList,
} from "../../interfaces";
import { IListCalculatedData } from "../../interfaces/calculate-list";

enum ACTION_TYPES {
  GET_LISTS = "[DASHBOARD LISTS]: get lists",
  GET_LISTS_ERROR = "[DASHBOARD LISTS]: get lists error",
  GET_LISTS_SUCCESS = "[DASHBOARD LISTS]: get lists success",

  TO_NEXT_LISTS_PAGE = "[DASHBOARD LISTS]: next page",
  TO_NEXT_LISTS_PAGE_SUCCESS = "[DASHBOARD LISTS]: next page success",

  CHANGE_PAGE_WITH_OR_WITHOUT_LOAD_DATA = "[DASHBOARD LISTS]: change page with or without load data",
  CHANGE_PAGE_WITHOUT_LOAD_DATA = "[DASHBOARD LISTS]: change page without load data",
  CHANGE_PAGE = "[DASHBOARD LISTS]: change current page",
  CHANGE_PAGE_SUCCESS = "[DASHBOARD LISTS]: change current page success",

  RELOAD_LISTS = "[DASHBOARD LISTS]: reload lists",
  RELOAD_LISTS_ERROR = "[DASHBOARD LISTS]: reload lists error",
  RELOAD_LISTS_SUCCESS = "[DASHBOARD LISTS]: reload lists success",

  LIST_NAVIGATE = "[DASHBOARD LISTS]: product navigate",
  LIST_NAVIGATE_CANCEL = "[DASHBOARD LISTS]: product navigate cancel",

  RENAME_LIST = "[DASHBOARD LISTS]: rename list",
  RENAME_LIST_ERROR = "[DASHBOARD LISTS]: rename list error",
  RENAME_LIST_SUCCESS = "[DASHBOARD LISTS]: rename list success",
  RENAME_LIST_CANCEL = "[DASHBOARD LISTS]: rename list cancel",

  DUPLICATE_LIST = "[DASHBOARD LISTS]: duplicate list",
  DUPLICATE_LIST_ERROR = "[DASHBOARD LISTS]: duplicate list error",
  DUPLICATE_LIST_SUCCESS = "[DASHBOARD LISTS]: duplicate list success",

  CREATE_CUSTOM_LIST = "[DASHBOARD LISTS]: Create custom list",
  CREATE_CUSTOM_LIST_ERROR = "[DASHBOARD LISTS]: Create custom list error",
  CREATE_CUSTOM_LIST_SUCCESS = "[DASHBOARD LISTS]: Create custom list success",

  SHOW_DELETE_LIST_POP_UP = "[DASHBOARD LISTS]: show delete list pop up",
  SHOW_DELETE_CUSTOM_POP_UP = "[DASHBOARD LISTS]: show delete custom list pop up",

  GO_TO_CUSTOM_LIST = "[DASHBOARD LISTS] go to custom list",

  DELETE_LIST = "[DASHBOARD LISTS]: delete list",
  DELETE_LIST_ERROR = "[DASHBOARD LISTS]: delete list error",
  DELETE_LIST_SUCCESS = "[DASHBOARD LISTS]: delete list success",

  ASSIGN_LIST = "[DASHBOARD LISTS] assign list",
  ASSIGN_LIST_ERROR = "[DASHBOARD LISTS] assign list error",
  ASSIGN_LIST_SUCCESS = "[DASHBOARD LISTS] assign list success",

  CALCULATE_UPDATE = "[DASHBOARD LISTS] Calculate update",
  CALCULATE_UPDATE_SUCCESS = "[DASHBOARD LISTS] Calculate update Success",

  EXPORT_LIST = "[DASHBOARD LISTS] export list",

  SHOW_CDL_LIST_CHANGE_STATUS_POP_UP = "[DASHBOARD LISTS]: show cdl list change statuspop up",

  CDL_LIST_CHANGE_STATUS = "[DASHBOARD LISTS]: cdl list change status",
  CDL_LIST_CHANGE_STATUS_ERROR = "[DASHBOARD LISTS]: cdl list change status error",
  CDL_LIST_CHANGE_STATUS_SUCCESS = "[DASHBOARD LISTS]: cdl list change status success",

  CDL_LIST_REFRESH = "[DASHBOARD LISTS] cdl list refresh",
  CDL_LIST_REFRESH_ERROR = "[DASHBOARD LISTS] cdl list refresh error",
  CDL_LIST_REFRESH_SUCCESS = "[DASHBOARD LISTS] cdl list refresh success",

  LISTEN_SOCKET_COUNTS_REFRESHED = "[DASHBOARD LISTS]: listen socket counts refreshed",

  RESET_LISTS = "[DASHBOARD LISTS]: dashboard reset lists",
}

export const getDashboardListsAction: ActionCreator<
  ACTION_TYPES.GET_LISTS,
  () => Action<ACTION_TYPES.GET_LISTS>
> = createAction(ACTION_TYPES.GET_LISTS);
export const getDashboardListsErrorAction: CreateActionType<
  ACTION_TYPES.GET_LISTS_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_LISTS_ERROR, createPayload<IServerError>());
export const getDashboardListsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_LISTS_SUCCESS,
  IProductsResponse
> = createAction(
  ACTION_TYPES.GET_LISTS_SUCCESS,
  createPayload<IProductsResponse>(),
);

export const getDashboardListsNextPageAction: CreateActionType<
  ACTION_TYPES.TO_NEXT_LISTS_PAGE,
  IProductRequest
> = createAction(
  ACTION_TYPES.TO_NEXT_LISTS_PAGE,
  createPayload<IProductRequest>(),
);
export const getDashboardListsNextPageSuccessAction: CreateActionType<
  ACTION_TYPES.TO_NEXT_LISTS_PAGE_SUCCESS,
  IProductsResponse
> = createAction(
  ACTION_TYPES.TO_NEXT_LISTS_PAGE_SUCCESS,
  createPayload<IProductsResponse>(),
);

export const changePageWithOrWithoutLoadDataAction: CreateActionType<
  ACTION_TYPES.CHANGE_PAGE_WITH_OR_WITHOUT_LOAD_DATA,
  IChangePagePayload
> = createAction(
  ACTION_TYPES.CHANGE_PAGE_WITH_OR_WITHOUT_LOAD_DATA,
  createPayload<IChangePagePayload>(),
);
export const changePageWithoutLoadDataDashboardListsAction: CreateActionType<
  ACTION_TYPES.CHANGE_PAGE_WITHOUT_LOAD_DATA,
  number
> = createAction(
  ACTION_TYPES.CHANGE_PAGE_WITHOUT_LOAD_DATA,
  createPayload<number>(),
); // currentPage
export const changePageDashboardListsAction: CreateActionType<
  ACTION_TYPES.CHANGE_PAGE,
  number
> = createAction(ACTION_TYPES.CHANGE_PAGE, createPayload<number>()); // currentPage
export const changePageDashboardListsSuccessAction: CreateActionType<
  ACTION_TYPES.CHANGE_PAGE_SUCCESS,
  IProductsResponse
> = createAction(
  ACTION_TYPES.CHANGE_PAGE_SUCCESS,
  createPayload<IProductsResponse>(),
);

export const reloadDashboardListsAction: ActionCreator<
  ACTION_TYPES.RELOAD_LISTS,
  () => Action<ACTION_TYPES.RELOAD_LISTS>
> = createAction(ACTION_TYPES.RELOAD_LISTS);
export const reloadDashboardListsErrorAction: CreateActionType<
  ACTION_TYPES.RELOAD_LISTS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.RELOAD_LISTS_ERROR,
  createPayload<IServerError>(),
);
export const reloadDashboardListsSuccessAction: CreateActionType<
  ACTION_TYPES.RELOAD_LISTS_SUCCESS,
  IProductsResponse
> = createAction(
  ACTION_TYPES.RELOAD_LISTS_SUCCESS,
  createPayload<IProductsResponse>(),
);

export const listNavigateAction: CreateActionType<
  ACTION_TYPES.LIST_NAVIGATE,
  IProductNavigationPayload
> = createAction(
  ACTION_TYPES.LIST_NAVIGATE,
  createPayload<IProductNavigationPayload>(),
);
export const listNavigateCancelAction: ActionCreator<
  ACTION_TYPES.LIST_NAVIGATE_CANCEL,
  () => Action<ACTION_TYPES.LIST_NAVIGATE_CANCEL>
> = createAction(ACTION_TYPES.LIST_NAVIGATE_CANCEL);

export const renameDashboardListAction: CreateActionType<
  ACTION_TYPES.RENAME_LIST,
  IRenameListPayload
> = createAction(ACTION_TYPES.RENAME_LIST, createPayload<IRenameListPayload>());
export const renameDashboardListErrorAction: CreateActionType<
  ACTION_TYPES.RENAME_LIST_ERROR,
  IServerError
> = createAction(ACTION_TYPES.RENAME_LIST_ERROR, createPayload<IServerError>());
export const renameDashboardListSuccessAction: CreateActionType<
  ACTION_TYPES.RENAME_LIST_SUCCESS,
  IListData
> = createAction(ACTION_TYPES.RENAME_LIST_SUCCESS, createPayload<IListData>());
export const renameDashboardListCancelAction: ActionCreator<
  ACTION_TYPES.RENAME_LIST_CANCEL,
  () => Action<ACTION_TYPES.RENAME_LIST_CANCEL>
> = createAction(ACTION_TYPES.RENAME_LIST_CANCEL);

export const duplicateDashboardListAction: CreateActionType<
  ACTION_TYPES.DUPLICATE_LIST,
  IDashboardDeleteEntity
> = createAction(
  ACTION_TYPES.DUPLICATE_LIST,
  createPayload<IDashboardDeleteEntity>(),
);
export const duplicateDashboardListErrorAction: CreateActionType<
  ACTION_TYPES.DUPLICATE_LIST_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.DUPLICATE_LIST_ERROR,
  createPayload<IServerError>(),
);
export const duplicateDashboardSuccessListAction: ActionCreator<
  ACTION_TYPES.DUPLICATE_LIST_SUCCESS,
  () => Action<ACTION_TYPES.DUPLICATE_LIST_SUCCESS>
> = createAction(ACTION_TYPES.DUPLICATE_LIST_SUCCESS);

export const createCustomListAction: CreateActionType<
  ACTION_TYPES.CREATE_CUSTOM_LIST,
  IListIdWithSelectedRecordType
> = createAction(
  ACTION_TYPES.CREATE_CUSTOM_LIST,
  createPayload<IListIdWithSelectedRecordType>(),
);
export const createCustomListErrorAction: CreateActionType<
  ACTION_TYPES.CREATE_CUSTOM_LIST_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.CREATE_CUSTOM_LIST_ERROR,
  createPayload<IServerError>(),
);
export const createCustomListSuccessAction: ActionCreator<
  ACTION_TYPES.CREATE_CUSTOM_LIST_SUCCESS,
  () => Action<ACTION_TYPES.CREATE_CUSTOM_LIST_SUCCESS>
> = createAction(ACTION_TYPES.CREATE_CUSTOM_LIST_SUCCESS);

export const showDeleteListPopUpAction: CreateActionType<
  ACTION_TYPES.SHOW_DELETE_LIST_POP_UP,
  IDashboardDeleteEntity
> = createAction(
  ACTION_TYPES.SHOW_DELETE_LIST_POP_UP,
  createPayload<IDashboardDeleteEntity>(),
);
export const showDeleteCustomListPopUpAction: CreateActionType<
  ACTION_TYPES.SHOW_DELETE_CUSTOM_POP_UP,
  IDashboardDeleteEntity
> = createAction(
  ACTION_TYPES.SHOW_DELETE_CUSTOM_POP_UP,
  createPayload<IDashboardDeleteEntity>(),
);

export const goToCustomListAction: CreateActionType<
  ACTION_TYPES.GO_TO_CUSTOM_LIST,
  IDashboardList
> = createAction(
  ACTION_TYPES.GO_TO_CUSTOM_LIST,
  createPayload<IDashboardList>(),
);

export const deleteDashboardListAction: CreateActionType<
  ACTION_TYPES.DELETE_LIST,
  IDashboardDeleteEntity
> = createAction(
  ACTION_TYPES.DELETE_LIST,
  createPayload<IDashboardDeleteEntity>(),
);
export const deleteDashboardListErrorAction: CreateActionType<
  ACTION_TYPES.DELETE_LIST_ERROR,
  IServerError
> = createAction(ACTION_TYPES.DELETE_LIST_ERROR, createPayload<IServerError>());
export const deleteDashboardListSuccessAction: ActionCreator<
  ACTION_TYPES.DELETE_LIST_SUCCESS,
  () => Action<ACTION_TYPES.DELETE_LIST_SUCCESS>
> = createAction(ACTION_TYPES.DELETE_LIST_SUCCESS);

export const assignDashboardListAction: CreateActionType<
  ACTION_TYPES.ASSIGN_LIST,
  IListAssign
> = createAction(ACTION_TYPES.ASSIGN_LIST, createPayload<IListAssign>());
export const assignDashboardListErrorAction: CreateActionType<
  ACTION_TYPES.ASSIGN_LIST_ERROR,
  IServerError
> = createAction(ACTION_TYPES.ASSIGN_LIST_ERROR, createPayload<IServerError>());
export const assignDashboardListSuccessAction: ActionCreator<
  ACTION_TYPES.ASSIGN_LIST_SUCCESS,
  () => Action<ACTION_TYPES.ASSIGN_LIST_SUCCESS>
> = createAction(ACTION_TYPES.ASSIGN_LIST_SUCCESS);

export const calculateDashboardListUpdateAction: CreateActionType<
  ACTION_TYPES.CALCULATE_UPDATE,
  IListCalculatedData
> = createAction(
  ACTION_TYPES.CALCULATE_UPDATE,
  createPayload<IListCalculatedData>(),
);
export const calculateDashboardListUpdateSuccessAction: CreateActionType<
  ACTION_TYPES.CALCULATE_UPDATE_SUCCESS,
  IListCalculatedData
> = createAction(
  ACTION_TYPES.CALCULATE_UPDATE_SUCCESS,
  createPayload<IListCalculatedData>(),
);

export const exportDashboardListAction: CreateActionType<
  ACTION_TYPES.EXPORT_LIST,
  IExportDashboardList
> = createAction(
  ACTION_TYPES.EXPORT_LIST,
  createPayload<IExportDashboardList>(),
);

export const showCDLListChangeStatusPopUpAction: CreateActionType<
  ACTION_TYPES.SHOW_CDL_LIST_CHANGE_STATUS_POP_UP,
  ICDLListChangeStatusPayload
> = createAction(
  ACTION_TYPES.SHOW_CDL_LIST_CHANGE_STATUS_POP_UP,
  createPayload<ICDLListChangeStatusPayload>(),
);

export const changeCDLListStatusAction: CreateActionType<
  ACTION_TYPES.CDL_LIST_CHANGE_STATUS,
  number
> = createAction(ACTION_TYPES.CDL_LIST_CHANGE_STATUS, createPayload<number>());
export const changeCDLListStatusErrorAction: CreateActionType<
  ACTION_TYPES.CDL_LIST_CHANGE_STATUS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.CDL_LIST_CHANGE_STATUS_ERROR,
  createPayload<IServerError>(),
);
export const changeCDLListStatusSuccessAction: CreateActionType<
  ACTION_TYPES.CDL_LIST_CHANGE_STATUS_SUCCESS,
  number
> = createAction(
  ACTION_TYPES.CDL_LIST_CHANGE_STATUS_SUCCESS,
  createPayload<number>(),
);

export const refreshCDLListAction: CreateActionType<
  ACTION_TYPES.CDL_LIST_REFRESH,
  number
> = createAction(ACTION_TYPES.CDL_LIST_REFRESH, createPayload<number>());
export const refreshCDLListErrorAction: CreateActionType<
  ACTION_TYPES.CDL_LIST_REFRESH_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.CDL_LIST_REFRESH_ERROR,
  createPayload<IServerError>(),
);
export const refreshCDLListSuccessAction: ActionCreator<
  ACTION_TYPES.CDL_LIST_REFRESH_SUCCESS,
  () => Action<ACTION_TYPES.CDL_LIST_REFRESH_SUCCESS>
> = createAction(ACTION_TYPES.CDL_LIST_REFRESH_SUCCESS);

export const listenSocketCountsRefreshed: ActionCreator<
  ACTION_TYPES.LISTEN_SOCKET_COUNTS_REFRESHED,
  () => Action<ACTION_TYPES.LISTEN_SOCKET_COUNTS_REFRESHED>
> = createAction(ACTION_TYPES.LISTEN_SOCKET_COUNTS_REFRESHED);

export const resetDashboardListsAction: ActionCreator<
  ACTION_TYPES.RESET_LISTS,
  () => Action<ACTION_TYPES.RESET_LISTS>
> = createAction(ACTION_TYPES.RESET_LISTS);
