import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { CREATE_VIEW_LIST_TYPE } from "../../constants";

enum ACTION_TYPES {
  SET_TYPE = "[CREATE LIST FROM VIEW] Set type",
  RESET_TYPE = "[CREATE LIST FROM VIEW] Reset type",
}

export const setCreateListFromViewTypeAction: CreateActionType<
  ACTION_TYPES.SET_TYPE,
  CREATE_VIEW_LIST_TYPE
> = createAction(ACTION_TYPES.SET_TYPE, createPayload<CREATE_VIEW_LIST_TYPE>());
export const resetCreateListFromViewTypeAction: ActionCreator<
  ACTION_TYPES.RESET_TYPE,
  () => Action<ACTION_TYPES.RESET_TYPE>
> = createAction(ACTION_TYPES.RESET_TYPE);
