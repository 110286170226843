import { Params } from "@angular/router";
import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import {
  IBidsRecords,
  IProductResultsResponse,
} from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

enum ACTION_TYPES {
  GET_QUICK_SEARCH_BIDS = "[QUICK SEARCH BIDS]: Get",
  GET_QUICK_SEARCH_BIDS_ERROR = "[QUICK SEARCH BIDS]: Get error",
  GET_QUICK_SEARCH_BIDS_SUCCESS = "[QUICK SEARCH BIDS]: Get success",

  SET_QUICK_SEARCH_BIDS_SPINNER_STATE = "[QUICK SEARCH BIDS] Set spinner state",

  CHANGE_QUICK_SEARCH_BIDS_PAGE = "[QUICK SEARCH BIDS] Change page",
  CHANGE_QUICK_SEARCH_BIDS_QUERY = "[QUICK SEARCH BIDS] Change query",

  RESET_QUICK_SEARCH_BIDS_STATE = "[QUICK SEARCH BIDS] Reset state",
}

export const getQuickSearchBidsAction: ActionCreator<
  ACTION_TYPES.GET_QUICK_SEARCH_BIDS,
  () => Action<ACTION_TYPES.GET_QUICK_SEARCH_BIDS>
> = createAction(ACTION_TYPES.GET_QUICK_SEARCH_BIDS);
export const getQuickSearchBidsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_QUICK_SEARCH_BIDS_SUCCESS,
  IProductResultsResponse<IBidsRecords>
> = createAction(
  ACTION_TYPES.GET_QUICK_SEARCH_BIDS_SUCCESS,
  createPayload<IProductResultsResponse<IBidsRecords>>(),
);
export const getQuickSearchBidsErrorAction: CreateActionType<
  ACTION_TYPES.GET_QUICK_SEARCH_BIDS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_QUICK_SEARCH_BIDS_ERROR,
  createPayload<IServerError>(),
);

export const changeQuickSearchBidsPageAction: CreateActionType<
  ACTION_TYPES.CHANGE_QUICK_SEARCH_BIDS_PAGE,
  number
> = createAction(
  ACTION_TYPES.CHANGE_QUICK_SEARCH_BIDS_PAGE,
  createPayload<number>(),
);
export const changeQuickSearchBidsQueryAction: CreateActionType<
  ACTION_TYPES.CHANGE_QUICK_SEARCH_BIDS_QUERY,
  Params
> = createAction(
  ACTION_TYPES.CHANGE_QUICK_SEARCH_BIDS_QUERY,
  createPayload<Params>(),
);
export const setQuickSearchResultsBidsSpinnerStateAction: CreateActionType<
  ACTION_TYPES.SET_QUICK_SEARCH_BIDS_SPINNER_STATE,
  boolean
> = createAction(
  ACTION_TYPES.SET_QUICK_SEARCH_BIDS_SPINNER_STATE,
  createPayload<boolean>(),
);

export const resetQuickSearchResultsBidsStateAction: ActionCreator<
  ACTION_TYPES.RESET_QUICK_SEARCH_BIDS_STATE,
  () => Action<ACTION_TYPES.RESET_QUICK_SEARCH_BIDS_STATE>
> = createAction(ACTION_TYPES.RESET_QUICK_SEARCH_BIDS_STATE);
