import { createAction, Action } from "@ngrx/store";
import { ActionCreator } from "@ngrx/store/src/models";

import { createPayload } from "@shared/utils/store";
import { CreateActionType } from "../../../../store/types/action.types";

import { IListData, IListRequest } from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";
import {
  IGenerateFile,
  IGenerateFileSuccess,
} from "@shared/modules/download-entity/interfaces/generate-file";

enum ACTION_TYPES {
  GET_DOWNLOAD_ENTITY_INFO = "[DOWNLOAD ENTITY]: Get Download entity info",
  GET_DOWNLOAD_ENTITY_INFO_ERROR = "[DOWNLOAD ENTITY] Get Download entity info error",
  GET_DOWNLOAD_ENTITY_INFO_SUCCESS = "[DOWNLOAD ENTITY]: Get Download entity info success",

  GENERATE_DOWNLOAD_ENTITY_FILE = "[DOWNLOAD ENTITY]: Generate Download entity file",
  GENERATE_DOWNLOAD_ENTITY_FILE_ERROR = "[DOWNLOAD ENTITY]: Generate Download entity file error",
  GENERATE_DOWNLOAD_ENTITY_FILE_SUCCESS = "[DOWNLOAD ENTITY]: Generate Download entity file success",

  DOWNLOAD_ENTITY_BY_DIRECT = "[DOWNLOAD ENTITY]: Start direct download entity",
  DOWNLOAD_ENTITY_BY_EMAIL = "[DOWNLOAD ENTITY]: Start email download entity",
}

export const getDownloadEntityInfoAction: CreateActionType<
  ACTION_TYPES.GET_DOWNLOAD_ENTITY_INFO,
  IListRequest
> = createAction(
  ACTION_TYPES.GET_DOWNLOAD_ENTITY_INFO,
  createPayload<IListRequest>(),
);
export const getDownloadEntityInfoErrorAction: CreateActionType<
  ACTION_TYPES.GET_DOWNLOAD_ENTITY_INFO_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_DOWNLOAD_ENTITY_INFO_ERROR,
  createPayload<IServerError>(),
);
export const getDownloadEntityInfoSuccessAction: CreateActionType<
  ACTION_TYPES.GET_DOWNLOAD_ENTITY_INFO_SUCCESS,
  IListData
> = createAction(
  ACTION_TYPES.GET_DOWNLOAD_ENTITY_INFO_SUCCESS,
  createPayload<IListData>(),
);

export const generateDownloadEntityFileAction: CreateActionType<
  ACTION_TYPES.GENERATE_DOWNLOAD_ENTITY_FILE,
  IGenerateFile
> = createAction(
  ACTION_TYPES.GENERATE_DOWNLOAD_ENTITY_FILE,
  createPayload<IGenerateFile>(),
);
export const generateDownloadEntityFileErrorAction: CreateActionType<
  ACTION_TYPES.GENERATE_DOWNLOAD_ENTITY_FILE_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GENERATE_DOWNLOAD_ENTITY_FILE_ERROR,
  createPayload<IServerError>(),
);
export const generateDownloadEntityFileSuccessAction: CreateActionType<
  ACTION_TYPES.GENERATE_DOWNLOAD_ENTITY_FILE_SUCCESS,
  IGenerateFileSuccess
> = createAction(
  ACTION_TYPES.GENERATE_DOWNLOAD_ENTITY_FILE_SUCCESS,
  createPayload<IGenerateFileSuccess>(),
);

export const downloadEntityByDirectAction: CreateActionType<
  ACTION_TYPES.DOWNLOAD_ENTITY_BY_DIRECT,
  IGenerateFileSuccess
> = createAction(
  ACTION_TYPES.DOWNLOAD_ENTITY_BY_DIRECT,
  createPayload<IGenerateFileSuccess>(),
);
export const downloadEntityByEmailAction: ActionCreator<
  ACTION_TYPES.DOWNLOAD_ENTITY_BY_EMAIL,
  () => Action<ACTION_TYPES.DOWNLOAD_ENTITY_BY_EMAIL>
> = createAction(ACTION_TYPES.DOWNLOAD_ENTITY_BY_EMAIL);
